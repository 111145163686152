import { Box, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Tree from "react-d3-tree";
import { useDispatch } from "react-redux";
import { updateScreenTitle } from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import CountBox from "./Panels/CountBox";
import ConsumerDash from "../ConsumerDash";
import CoordinatorDash from "../CoordinatorDash";
import { AUTH_KEY, USER_TYPES } from "../../../Configurations/AppConfig";
import ShopOwnerDash from "../ShopOwnerDash";

const Dashboard = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateScreenTitle("My Dashboard"));
    return () => dispatch(updateScreenTitle(""));
  }, []);

  const authInfo = localStorage.getItem(AUTH_KEY) || sessionStorage.getItem(AUTH_KEY);
  if (authInfo) {
    const auth = JSON.parse(authInfo);
    const userType = auth?.principal?.type || "";
    if (userType === USER_TYPES.COORDINATOR) {
      return <CoordinatorDash />;
    } else if (userType === USER_TYPES.CONSUMER) {
      return <ConsumerDash />;
    } else if (userType === USER_TYPES.SHOP_OWNER) {
      return <ShopOwnerDash />;
    }
  }

  return (
    <Box>
      <Typography>Something went wrong,please reload the application</Typography>{" "}
    </Box>
  );
};

export default Dashboard;

import { Box, MenuItem, Select } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";
import uuid from "react-uuid";

const WdSelect = (props) => {
  const { children, loading } = props;
  const array = Array.isArray(children) ? children : [];

  let extraProps = {};
  if (loading) {
    extraProps.IconComponent = () => <Spinner />;
  }

  const Spinner = () => {
    return (
      <Box mr={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <RotatingLines
          style={{ marginRight: 20, color: "red" }}
          visible={true}
          height="20"
          width="20"
          // color="#ffffff"
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{ color: "#ffffff" }}
          wrapperClass=""
        />
      </Box>
    );
  };

  return (
    <Select {...extraProps} disabled={loading} {...props}>
      {array.map(({ key, value }) => (
        <MenuItem key={uuid()} value={key}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
};

export default WdSelect;

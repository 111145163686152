import { Box, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dismissLoader,
  showLoader,
  showSuccessMessage,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import { Field, Form, Formik } from "formik";
import WdForm from "../../../Components/WdForm";
import { object, string } from "yup";
import Spacer from "../../../Components/Spacer";
import AppService from "../../../Configurations/Appservice";

const initialValues = {
  name: "",
  address: "",
  contactNumber: "",
};

const validationSchema = object({
  name: string().required("Name is Mandatory"),
  address: string().required("Address is Mandatory"),
  contactNumber: string().required("Contact is Mandatory"),
});

const NewShop = (props) => {
  const [managedBy, setManagedBy] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateScreenTitle("New Shop"));
    return () => dispatch(updateScreenTitle(""));
  }, []);

  useEffect(() => {
    dispatch(showLoader());
    AppService.get("treat/api/v1/coordinator/hierarchy")
      .then((response) => {
        setManagedBy(response);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  }, []);

  const _onSubmit = (values, { resetForm }) => {
    dispatch(showLoader());

    const body = {
      ...values,
      hierarchyId: managedBy.id,
    };

    AppService.post("treat/api/v1/shop", body)
      .then((response) => {
        dispatch(showSuccessMessage("Shop created successfully"));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={_onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, isValid, touched, dirty }) => (
          <Form>
            <WdForm>
              <Typography color="primary" variant="subtitle2" fontSize={14} my={1} mb={2}>
                Basic Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    required
                    label="Managed By"
                    placeholder="Managed By"
                    fullWidth
                    disabled
                    value={managedBy ? managedBy.name + " " + managedBy.type : ""}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    required
                    name="name"
                    label="Shop Name"
                    placeholder="Shop Name"
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.name) && Boolean(touched.name)}
                    helperText={Boolean(touched.name) && errors.name}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <Field
                      name="contactNumber"
                      label="Contact Number"
                      required
                      placeholder="Contact Number"
                      fullWidth
                      as={TextField}
                      multiline
                      error={Boolean(errors.contactNumber) && Boolean(touched.contactNumber)}
                      helperText={Boolean(touched.contactNumber) && errors.contactNumber}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <Field
                      name="address"
                      label="Address"
                      required
                      placeholder="Address"
                      fullWidth
                      as={TextField}
                      multiline
                      error={Boolean(errors.address) && Boolean(touched.address)}
                      helperText={Boolean(touched.address) && errors.address}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Spacer />
            </WdForm>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default NewShop;

import { Box, Card, Typography } from "@mui/material";
import pxToRem from "../../../../Themes/Functions/pxToRem";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";

const ProfileCard = (props) => {
  return (
    <Card
      sx={{
        height: `calc((100vh - ${pxToRem(96)})*0.4)`,
        overflow: "hidden",
        margin: pxToRem(16),
        cursor: "pointer",
        mt: 0,
        p: 2,
        boxShadow: "-4px -5px 4px 0 rgba(217, 217, 233,0.3),4px 4px 6px 0 rgba(175, 175, 213, 0.6)",

        //   p: 2,
      }}
      {...props}
      // bgcolor="green"
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <ManageAccountsOutlinedIcon sx={{ fontSize: 60 }} color="primary" />
        <Typography>Settings</Typography>
      </Box>
    </Card>
  );
};

export default ProfileCard;

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import pxToRem from "../../../../Themes/Functions/pxToRem";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showSuccessMessage } from "../../../../LayoutContainers/AppLayout/redux/AppSlice";
import AppService from "../../../../Configurations/Appservice";

const AllocatedAmount = ({ fundAllocation, loading, onSucess }) => {
  const [open, setOpen] = useState(false);
  const [isClaiming, claiming] = useState(false);
  const [amount, setAmount] = useState(fundAllocation.amount || 0);

  const dispatch = useDispatch();

  const _handleClose = () => {
    setOpen(false);
  };

  const _claimHandler = () => {
    claiming(true);
    const params = { amount: fundAllocation.amount };
    AppService.post("treat/api/v1/fundAllocation/claim", null, { params })
      .then((response) => {
        onSucess(fundAllocation.amount);
        dispatch(showSuccessMessage("Congrats you have successfully claimed your amount"));
      })
      .catch(() => {})
      .finally(() => {
        claiming(false);
        setOpen(false);
      });
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={_handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isClaiming && <LinearProgress />}
        <DialogTitle id="alert-dialog-title">{"Are You Sure ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to claim this amount?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={_handleClose}>Disagree</Button>
          <Button onClick={_claimHandler} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Card
        sx={{
          display: "flex",
          flexDirection: "row",
          height: `calc((100vh - ${pxToRem(144)})*0.3)`,
          margin: pxToRem(16),
          mr: 0,
          p: 1,
          px: 2,
          cursor: "pointer",
          boxShadow:
            "-4px -5px 4px 0 rgba(217, 217, 233,0.3),4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
        }}
        bgcolor="yellow"
      >
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Typography color="#00796B" variant="h4">
            Allocated Amount
          </Typography>
          <Box sx={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "center" }}>
            {!loading && (
              <Typography sx={{ fontSize: 50, fontWeight: 500 }}>
                {fundAllocation.amount || 0} ₹
              </Typography>
            )}
            {loading && <CircularProgress sx={{ mx: 2 }} />}
          </Box>
        </Box>
        <Box
          sx={{
            p: 2,
            pr: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {fundAllocation.amount > 0 && (
            <Box
              onClick={() => setOpen(true)}
              sx={{
                height: 95,
                width: 95,
                background: "rgb(51,184,57)",
                background:
                  "linear-gradient(41deg, rgba(51,184,57,1) 21%, rgba(11,15,94,1) 100%, rgba(69,252,233,1) 100%)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
                borderRadius: 2,
                boxShadow:
                  "-4px -5px 4px 0 rgba(217, 217, 233,0.3),4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
              }}
            >
              <Typography color="#ffffff" fontSize={14} variant="h6">
                CLAIM
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default AllocatedAmount;

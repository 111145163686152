import { Field, Form, Formik } from "formik";
import WdForm from "../../../Components/WdForm";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import WdSelect from "../../../Components/WdSelect";
import { object, string } from "yup";
import { useEffect, useState } from "react";
import HierarchySelector from "../../../Components/HierarchySelector";
import { AREA, BRANCH, DISTRICT } from "../../../Configurations/AppConfig";
import { useDispatch } from "react-redux";
import {
  dismissLoader,
  showLoader,
  showSuccessMessage,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import AppService from "../../../Configurations/Appservice";
import { COORDINATOR_TYPE, USER_TYPE } from "../../../Configurations/AppConstants";
import uuid from "react-uuid";

const initialValues = {
  name: "",
  phoneNumber: "",
  password: "",
  address: "",
  email: "",
};

const validationSchema = object({
  name: string().required("Name is Mandatory"),
  phoneNumber: string().required("Phone Number is Mandatory"),
  password: string().required("Password is Mandatory"),
  address: string().required("Address is Mandatory"),
});

export default function NewCoordinator(props) {
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("");

  const [district, setDistrict] = useState();
  const [area, setArea] = useState();
  const [branch, setBranch] = useState();

  const [userHierarchy, setUserHierarchy] = useState();

  const [hierarchyList, setHierarchyList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateScreenTitle("New Coordinator"));
    return () => dispatch(updateScreenTitle(""));
  });

  useEffect(() => {
    dispatch(showLoader());
    AppService.get("treat/api/v1/coordinator/types")
      .then((response) => {
        const list = response.map((item) => ({ key: item, value: item }));
        setTypes(list);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  }, []);

  useEffect(() => {
    if (!type) return;

    if (type === COORDINATOR_TYPE.DISTRICT_COORDINATOR) {
      setHierarchyList([DISTRICT]);
    } else if (type === COORDINATOR_TYPE.AREA_COORDINATOR) {
      setHierarchyList([DISTRICT, AREA]);
    } else if (type === COORDINATOR_TYPE.BRANCH_COORDINATOR) {
      setHierarchyList([DISTRICT, AREA, BRANCH]);
    }
  }, [type]);

  const _onSubmit = (values, { resetForm }) => {
    dispatch(showLoader());
    let hierarchy = null;
    if (type === COORDINATOR_TYPE.DISTRICT_COORDINATOR) {
      hierarchy = district?.key;
    } else if (type === COORDINATOR_TYPE.AREA_COORDINATOR) {
      hierarchy = area?.key;
    } else if (type === COORDINATOR_TYPE.BRANCH_COORDINATOR) {
      hierarchy = branch?.key;
    }
    const body = {
      ...values,
      phoneNumber: "+91" + values.phoneNumber,
      coordinatorType: type,
      userHierarchyId: userHierarchy.key,
      hierarchyId: hierarchy,
    };

    AppService.post("treat/api/v1/coordinator", body)
      .then((response) => {
        resetForm();
        dispatch(showSuccessMessage("Coordinator created successfully"));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={_onSubmit} validationSchema={validationSchema}>
      {({ errors, isValid, touched, dirty }) => (
        <Form>
          <WdForm>
            <Typography color="primary" variant="subtitle2" mb={2}>
              Basic Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="service-label" required>
                      Coordinator Type
                    </InputLabel>
                    <WdSelect
                      label="Coordinator Type"
                      fullWidth
                      // required
                      value={type}
                      onChange={(event) => {
                        setType(event.target.value);
                      }}
                    >
                      {types}
                    </WdSelect>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    required
                    name="name"
                    label="Name"
                    placeholder="User Name"
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.name) && Boolean(touched.name)}
                    helperText={Boolean(touched.name) && errors.name}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="phoneNumber"
                    label="Phone Number"
                    placeholder="Phone Number"
                    required
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.phoneNumber) && Boolean(touched.phoneNumber)}
                    helperText={Boolean(touched.phoneNumber) && errors.phoneNumber}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <Typography fontSize={14} sx={{ fontWeight: "500" }}>
                            +91
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="password"
                    label="Password"
                    placeholder="Password"
                    required
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.password) && Boolean(touched.password)}
                    helperText={Boolean(touched.password) && errors.password}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="email"
                    label="Email"
                    placeholder="Email"
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.email) && Boolean(touched.email)}
                    helperText={Boolean(touched.email) && errors.email}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="address"
                    label="Address"
                    required
                    placeholder="Address"
                    fullWidth
                    as={TextField}
                    multiline
                    error={Boolean(errors.address) && Boolean(touched.address)}
                    helperText={Boolean(touched.address) && errors.address}
                  />
                </Box>
              </Grid>

              <HierarchySelector
                // key={uuid()}
                // list={hierarchyList}
                // onDistrictChange={setDistrict}
                // onAreaChange={setArea}
                onBranchChange={setUserHierarchy}
              />
            </Grid>

            <Typography color="primary" variant="subtitle2" my={2}>
              Hierarchy Details
            </Typography>
            <Grid container spacing={2}>
              <HierarchySelector
                // key={uuid()}
                list={hierarchyList}
                onDistrictChange={setDistrict}
                onAreaChange={setArea}
                onBranchChange={setBranch}
              />
            </Grid>
          </WdForm>
        </Form>
      )}
    </Formik>
  );
}

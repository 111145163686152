import linearGradient from "../../../Functions/linearGradient";
import pxToRem from "../../../Functions/pxToRem";
import borders from "../../basics/borders";
import colors from "../../basics/colors";

const { borderWidth, borderColor } = borders;
const { transparent, info } = colors;

const checkbox = {
  styleOverrides: {},
};

export default checkbox;

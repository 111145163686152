import { Box, Card, Typography } from "@mui/material";
import pxToRem from "../../../../Themes/Functions/pxToRem";
import OrderCard from "../../panels/OrderCard";
import MyOrderCard from "./MyOrderCard";

const OrderDetails = (props) => {
  return (
    <Card
      sx={{
        height: `calc((100vh - ${pxToRem(122)})*0.6)`,
        overflow: "scroll",
        p: 2,
        margin: pxToRem(16),
        boxShadow:
          "inset -4px -5px 4px 0 rgba(217, 217, 233,0.3),inset 4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
        //   mb: 0,
      }}
      // bgcolor="gray"
    >
      <Typography color="#673AB7" variant="h4">
        My Order Details
      </Typography>
      <Box sx={{ overflow: "scroll" }}>
        {[1, 2, 3, 4, 5, 1, 2, 3, 4, 5, 1, 2, 3, 4, 5, 1, 2, 3, 4, 5].map((item) => (
          <MyOrderCard />
        ))}
      </Box>
    </Card>
  );
};

export default OrderDetails;

import * as React from "react";

import { ThemeProvider } from "@mui/material/styles";

import { CssBaseline } from "@mui/material";
import theme from "./Themes/theme";
import LoginPage from "./ScreenLayouts/Auth/Login";
import { Routes, Route, useNavigate } from "react-router-dom";
import AppLayout2 from "./LayoutContainers/AppLayout2";
import AllTickets from "./ScreenLayouts/AllTickets";
import NewHierarchy from "./ScreenLayouts/Hierarchy/NewHierarchy";
import ErrorPage from "./ScreenLayouts/ErrorMessage";
import NewUser from "./ScreenLayouts/User/NewUser";
import NewShop from "./ScreenLayouts/Shop/NewShop";
import FundAllocation from "./ScreenLayouts/FundAllocation";
import ConsumerList from "./ScreenLayouts/Consumers/ConsumerList";
import NewConsumer from "./ScreenLayouts/Consumers/NewConsumer";
import ShopList from "./ScreenLayouts/Shop/ShopList";
import FundAllocationDetails from "./ScreenLayouts/FundAllocation/AllocationDetails";
import Dashboard from "./ScreenLayouts/Dashboard/Dashboard";
import HierarchyList from "./ScreenLayouts/Hierarchy/HierarchyList";
import NewShopOwner from "./ScreenLayouts/Shop/NewShopOwner";
import NewCoordinator from "./ScreenLayouts/Coordinator/NewCoordinator";
import CoordinatorList from "./ScreenLayouts/Coordinator/CoordinatorList";
import UserConfiguration from "./ScreenLayouts/Configuration/UserConfiguration";
import NewSponsor from "./ScreenLayouts/Sponsor/NewSponsor";
import SponsorShip from "./ScreenLayouts/Sponsor/SponsorShip";
import FundTransfer from "./ScreenLayouts/FundAllocation/FundTransfer";
import FundSettlement from "./ScreenLayouts/FundAllocation/FundSettlement";

export default function MyApp() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="*" element={<ErrorPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route element={<AppLayout2 />}>
          <Route path="coordinator/dash" element={<Dashboard />} />
          <Route path="allTickets" element={<AllTickets />} />
          <Route path="consumers" element={<ConsumerList />} />
          <Route path="newConsumer" element={<NewConsumer />} />
          <Route path="newHierarchy" element={<NewHierarchy />} />
          <Route path="hierarchyList" element={<HierarchyList />} />
          <Route path="newCoordinator" element={<NewCoordinator />} />
          <Route path="coordinatorList" element={<CoordinatorList />} />
          <Route path="newShop" element={<NewShop />} />
          <Route path="newShopOwner" element={<NewShopOwner />} />
          <Route path="shopList" element={<ShopList />} />
          <Route path="fundAllocation" element={<FundAllocation />} />
          <Route path="fundAllocationDetails" element={<FundAllocationDetails />} />
          <Route path="userConfiguration" element={<UserConfiguration />} />
          <Route path="newSponsor" element={<NewSponsor />} />
          <Route path="newSponsorShip" element={<SponsorShip />} />
          <Route path="fundTransfer" element={<FundTransfer />} />
          <Route path="fundSettlement" element={<FundSettlement />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

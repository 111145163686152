import { Icon } from "@mui/material";
import { BsWalletFill } from "react-icons/bs";
import { GiSettingsKnobs } from "react-icons/gi";

const partnerRoute = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "ptr",
    noCollapse: true,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Dashboard",
        key: "dash",
        route: "ptr/dash",
      },
    ],
  },
  // { type: "title", title: "Ticket" },
  {
    type: "collapse",
    name: "Ticket",
    key: "ADM-Ticket",
    noCollapse: true,
    icon: <Icon fontSize="medium">group-work</Icon>,
    collapse: [
      {
        name: "All Tickets",
        key: "pending-tickets",
        route: "ADM-Ticket/pending-tickets",
      },
    ],
  },
  // { type: "title", title: "Wallet" },
  {
    type: "collapse",
    name: "Wallet",
    key: "wallet",
    noCollapse: true,
    icon: <BsWalletFill />,
    collapse: [
      {
        name: "My Wallet",
        key: "my-wallet",
        route: "wallet/my-wallet",
      },
      {
        name: "Pending Payment",
        key: "pending-partner-payment",
        route: "wallet/pending-partner-payment",
      },
    ],
  },
  {
    type: "collapse",
    name: "Configurations",
    key: "ADM-APPCONFIG",
    icon: <GiSettingsKnobs />,
    collapse: [
      {
        name: "Reset Password",
        key: "reset_password",
        route: "ADM-APPCONFIG/reset_password",
      },
    ],
  },
];

export default partnerRoute;

import { createSlice } from "@reduxjs/toolkit";

const consumerDashSlice = createSlice({
  name: "consumerDashSlice",
  initialState: {
    transactions: [],
    orders: [],
  },
  reducers: {
    setTransactions: (state, { payload }) => {
      state.transactions = payload;
    },
    addTransaction: (state, { payload }) => {
      let transactions = state.transactions;
      transactions.unshift(payload);
      state.transactions = transactions;
    },
    setOrders: (state, action) => {},
    addOrder: (state, action) => {},
  },
});

export const { setTransactions, addTransaction, setOrders, addOrder } = consumerDashSlice.actions;

export default consumerDashSlice.reducer;

import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import HierarchySelector from "../../../Components/HierarchySelector";
import ChipButton from "../../../Components/ChipButton";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  dismissLoader,
  showLoader,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import WdTable from "../../../Components/Table/WdTable";
import ScreenBox from "../../../Components/ScreenBox";
import AppService from "../../../Configurations/Appservice";
import { createColumnHelper } from "@tanstack/react-table";
import DetailsIcon from "@mui/icons-material/Details";
import IconButton from "@mui/material/IconButton";

const columnHelper = createColumnHelper();
const columnsData = [
  columnHelper.accessor("name", {
    header: "Shop Name",
  }),
  columnHelper.accessor("shopNumber", {
    header: "Shop Number",
  }),
  columnHelper.accessor("contactNumber", {
    header: "Contact Number",
  }),
  columnHelper.accessor("hierarchyName", {
    header: "Hierarchy Name",
  }),
  columnHelper.accessor("hierarchyType", {
    header: "Hierarchy Type",
  }),
  columnHelper.accessor("details", {
    header: "Details",
    cell: ({ row }) => (
      <IconButton>
        <DetailsIcon />
      </IconButton>
    ),
  }),
];

const TYPES = ["STATE", "DISTRICT", "AREA", "BRANCH"];

const HierarchyList = (props) => {
  const dispatch = useDispatch();
  const [district, setDistrict] = useState();
  const [area, setArea] = useState();
  const [branch, setBranch] = useState();
  const [list, setList] = useState([]);
  const [type, setType] = useState("");

  useEffect(() => {
    dispatch(updateScreenTitle("Hierarchies"));
    return () => dispatch(updateScreenTitle(""));
  });

  const _search = () => {
    // dispatch(showLoader());
    // const hierarchyId = branch?.key || area?.key || district?.key;
    // const params = { hierarchyId };
    // AppService.get("treat/api/v1/shop/search", { params })
    //   .then((response) => {
    //     setList(response);
    //   })
    //   .catch(() => {})
    //   .finally(() => {
    //     dispatch(dismissLoader());
    //   });
  };

  return (
    <ScreenBox>
      <Grid container spacing={2} mb={2}>
        <HierarchySelector
          gridWidth={10 / 4}
          onDistrictChange={setDistrict}
          onAreaChange={setArea}
          onBranchChange={setBranch}
        />
        <Grid item xs={10 / 4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              value={type}
              label="Type"
              onChange={(event) => {
                setType(event.target.value);
              }}
            >
              {TYPES.map((type) => (
                <MenuItem value={type}>{type}</MenuItem>
              ))}
              {/* <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <ChipButton fullWidth onClick={_search}>
            Search
          </ChipButton>
        </Grid>
      </Grid>
      <WdTable columnsData={columnsData} data={list} />
    </ScreenBox>
  );
};

export default HierarchyList;

import {
  Box,
  Chip,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import AppService from "../../../Configurations/Appservice";
import { useDispatch } from "react-redux";
import { showSuccessMessage } from "../../../LayoutContainers/AppLayout/redux/AppSlice";

const FundAllocationCard = ({ allocation }) => {
  const [amount, setAmount] = useState(allocation.amount);
  const [isLoading, loading] = useState(false);

  const dispatch = useDispatch();

  const _onSubmit = (props) => {
    loading(true);
    const payload = {
      consumerId: allocation.consumerId,
      month: allocation.month,
      amount,
    };
    AppService.post("treat/api/v1/fundAllocation", payload)
      .then((respone) => {
        dispatch(showSuccessMessage("Allocation is successfull"));
      })
      .catch(() => {})
      .finally(() => {
        loading(false);
      });
  };

  const ActionButton = () => {
    if (allocation?.status === "UNCLAIMED") {
      return (
        <Chip
          disabled={allocation.amount == amount || isLoading}
          label={allocation?.id != null ? "EDIT" : "SAVE"}
          color="primary"
          sx={{ mt: 2, minWidth: 100, borderRadius: 2 }}
          onClick={_onSubmit}
        />
      );
    } else {
      return (
        <Chip
          label="CLAIMED"
          color="primary"
          sx={{ mt: 2, minWidth: 100, borderRadius: 2, backgroundColor: "#BDBDBD" }}
        />
      );
    }
  };

  return (
    <Paper
      sx={{
        boxShadow: "-2px -3px 2px 0 rgba(217, 217, 233,0.3),2px 3px 2px 0 rgba(175, 175, 213, 0.6)",
      }}
    >
      {isLoading && <LinearProgress />}
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}>
        <Typography variant="button" mb={1}>
          {allocation.month}
        </Typography>
        <TextField
          fullWidth
          disabled={isLoading}
          type="number"
          value={amount}
          placeholder="Enter Amount"
          onChange={(event) => {
            setAmount(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography fontSize={16}>₹</Typography>
              </InputAdornment>
            ),
          }}
        />
        <ActionButton />
      </Box>
    </Paper>
  );
};

export default FundAllocationCard;

import { Avatar, Box, Chip, Grid, Skeleton, Typography } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CallReceivedIcon from "@mui/icons-material/CallReceived";

const TransactionCardItemLoader = (props) => {
  return (
    <Box
      my={2}
      sx={{
        borderBottom: 1,
        pb: 1,
        borderColor: "#ECEFF1",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Skeleton variant="circular">
        <Avatar />
      </Skeleton>

      <Grid container ml={2}>
        <Grid item xs={4}>
          <Skeleton width="70%" height={20} />
          <Skeleton width="30%" height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton width="70%" height={20} />
          <Skeleton width="30%" height={20} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton width="80%" height={20} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton width="80%" height={20} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionCardItemLoader;

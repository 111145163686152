import { createTheme } from "@mui/material";
import borders from "./basics/borders";
import boxShadows from "./basics/boxShadows";
import breakpoints from "./basics/breakpoints";
import colors from "./basics/colors";
import typography from "./basics/typography";

import hexToRgb from "../Functions/hexToRgb";
import linearGradient from "../Functions/linearGradient";
import pxToRem from "../Functions/pxToRem";
import rgba from "../Functions/rgba";
import boxShadow from "../Functions/boxShadow";
import inputOutlined from "./components/input/inputOutlined";
import input from "./components/input/input";
import inputLabel from "./components/input/inputLabel";
import button from "./components/button";
import switchButton from "./components/switchButton";
import drawer from "./components/drawer";
import divider from "./components/divider";
import list from "./components/list";
import listItem from "./components/list/listItem";
import listItemText from "./components/list/listItemText";
import icon from "./components/icon";
import link from "./components/link";
import avatar from "./components/avatar";
import appBar from "./components/appBar";
import card from "./components/card";
import cardMedia from "./components/card/cardMedia";
import cardContent from "./components/card/cardContent";
import iconButton from "./components/iconButton";
import tableContainer from "./components/table/tableContainer";
import autocomplete from "./components/form/autocomplete";
import checkbox from "./components/form/checkbox";
import tableCell from "./components/table/tableCell";

const theme = createTheme({
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },
  components: {
    // MuiDrawer: { ...drawer },
    MuiOutlinedInput: { ...inputOutlined },
    MuiInput: { ...input },
    MuiInputLabel: { ...inputLabel },
    MuiButton: { ...button },
    MuiSwitch: { ...switchButton },
    MuiDivider: { ...divider },
    MuiAvatar: { ...avatar },
    MuiList: { ...list },
    MuiListItem: { ...listItem },
    MuiListItemText: { ...listItemText },
    MuiIcon: { ...icon },
    MuiLink: { ...link },
    MuiAppBar: { ...appBar },
    MuiCard: { ...card },
    MuiCardMedia: { ...cardMedia },
    MuiCardContent: { ...cardContent },
    MuiIconButton: { ...iconButton },
    MuiTableContainer: { ...tableContainer },
    MuiAutocomplete: { ...autocomplete },
    MuiCheckbox: { ...checkbox },
    MuiTableCell: { ...tableCell },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
    },
  },
});

export default theme;

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuList from "./panels/Appdrawer/DrawerList";
import { HiMenu } from "react-icons/hi";
import pxToRem from "../../Themes/Functions/pxToRem";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import WDLoader from "../../Components/AppLoader/WDLoader";
import WDSuccessToast from "../../Components/SuccessToast";
import WDErrorToast from "../../Components/ErrorToast";
import { useDispatch, useSelector } from "react-redux";
import {
  dismissErrorMessage,
  dismissSuccessMessage,
  updateAuthInfo,
} from "../AppLayout/redux/AppSlice";
import { Avatar, Grid, Menu, Tooltip } from "@mui/material";
import { IoMdLogOut } from "react-icons/io";
import WDButton from "../../MaterialComponents/WDButton";
import linearGradient from "../../Themes/Functions/linearGradient";
import logoutSvg from "../../assets/images/logout.svg";
import { AUTH_KEY } from "../../Configurations/AppConfig";
import { TOCAMELCASE, TO_CAMELSNAKE } from "../../Configurations/JsUtil";

const drawerWidth = 240;

let backgroundValue = linearGradient("#A52E2E", "#B72323"); // "#A52E2E";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  background: backgroundValue,
  borderTopRightRadius: 15,
  borderBottomRightRadius: 15,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  background: backgroundValue,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  borderTopRightRadius: 15,
  borderBottomRightRadius: 15,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const DrawerOpenHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${65}px)`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const dispatch = useDispatch();
  const appStore = useSelector((state) => state.app);

  const principalInfo = appStore.auth?.principal || {};
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const authInfo = localStorage.getItem(AUTH_KEY) || sessionStorage.getItem(AUTH_KEY);
  if (!authInfo || !JSON.parse(authInfo)) {
    return <Navigate to="/" />;
  } else if (localStorage.getItem(AUTH_KEY) && !sessionStorage.getItem(AUTH_KEY)) {
    sessionStorage.setItem(AUTH_KEY, localStorage.getItem(AUTH_KEY));
  }

  // if (authInfo) {
  //   dispatch(updateAuthInfo(JSON.parse(authInfo)));
  // }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const _logoutHandler = () => {
    localStorage.removeItem(AUTH_KEY);
    sessionStorage.removeItem(AUTH_KEY);
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <WDLoader open={appStore.showLoader} />
      <WDSuccessToast
        open={appStore.successMessage.show}
        onClose={() => dispatch(dismissSuccessMessage())}
        message={appStore.successMessage.message}
      />
      <WDErrorToast
        open={appStore.errorMessage.show}
        onClose={() => dispatch(dismissErrorMessage())}
        message={appStore.errorMessage.message}
      />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Typography variant="h5" noWrap sx={{}}>
            {appStore.screenTitle || "Treat Application"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "row-reverse",
              alignItems: "center",
              // backgroundColor: "green",
            }}
          >
            <Tooltip placement="top" title="logout">
              <Box
                component="img"
                src={logoutSvg}
                onClick={handleClick}
                sx={{ height: pxToRem(20), width: "auto", cursor: "pointer" }}
              />
            </Tooltip>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Box p={2}>
                <Typography fontSize={14}>Are you sure you want to logout ?</Typography>
              </Box>
              <Grid container>
                <Grid item md={6}>
                  <WDButton variant="text" onClick={handleClose}>
                    cancel
                  </WDButton>
                </Grid>
                <Grid item md={6}>
                  <WDButton variant="text" onClick={_logoutHandler}>
                    logout
                  </WDButton>
                </Grid>
              </Grid>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        {!open && (
          <DrawerHeader>
            <IconButton color="white" aria-label="open drawer" onClick={handleDrawerOpen}>
              <HiMenu />
            </IconButton>
          </DrawerHeader>
        )}
        {open && (
          <DrawerOpenHeader>
            <Box
              sx={{
                display: "flex",
                flex: 1,
                // bgcolor: "red",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: pxToRem(10),
              }}
            >
              <Typography variant="h6" color="white.main">
                Treat
              </Typography>
            </Box>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon color="white" />
            </IconButton>
          </DrawerOpenHeader>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "hidden",
          }}
        >
          <Box sx={{ display: "flex" }} p={1} pb={3}>
            <Avatar
              alt="Remy Sharp"
              src="https://47986.s3.ap-south-1.amazonaws.com/PROFILE_IMAGE/e99764d7-2cd3-4684-87f2-7a6f9351349fTreatUser.png"
              sx={{ width: 40, height: 40, mx: 0.5, mr: 1, border: 2, borderColor: "#ffffff" }}
            />
            <Box
              sx={({ transitions }) => ({
                opacity: open ? 1 : 0,
                transition: transitions.create(["opacity"], {
                  easing: transitions.easing.easeInOut,
                  duration: transitions.duration.standard,
                }),
              })}
            >
              <Typography fontSize={13} color="#ffffff" variant="h6">
                {principalInfo.name}
              </Typography>
              <Typography fontSize={12} sx={{ lineHeight: 1 }} color="white.main">
                {TOCAMELCASE(principalInfo.type)}
              </Typography>
            </Box>
          </Box>
          <MenuList open={open} minSideNav={!open} />
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          p: pxToRem(16),
          pt: 0,
          bgcolor: "#ffffff",
          pt: pxToRem(64),
          height: "100vh",
          overflow: "auto",
        }}
      >
        {/* <DrawerHeader /> */}
        <Outlet />
      </Box>
    </Box>
  );
}

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dismissLoader,
  showLoader,
  updateScreenTitle,
} from "../../LayoutContainers/AppLayout/redux/AppSlice";
import RecursiveSearchCard from "./panels/RecursiveSearchCard";
import AppService from "../../Configurations/Appservice";

const UserConfiguration = (props) => {
  const [recursiveSearch, setRecursiveSearch] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateScreenTitle("App Configurations"));
  }, []);

  useEffect(() => {
    dispatch(showLoader());
    AppService.get("treat/api/v1/userConfiguration")
      .then((response) => {
        setRecursiveSearch(response.recursiveSearch);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  }, []);

  return (
    <Box>
      <RecursiveSearchCard recursiveSearch={recursiveSearch} onUpdate={setRecursiveSearch} />
    </Box>
  );
};

export default UserConfiguration;

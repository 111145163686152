import pxToRem from "../../../Themes/Functions/pxToRem";
import OrderDetails from "./components/OrderDetails";
import ProfileCard from "./components/ProfileCard";
import TransactionCard from "./components/TransactionCard";
import WalletBalance from "./components/WalletBalance";
import AllocatedAmount from "./components/AllocatedAmount";
import { useEffect, useState } from "react";
import AppService from "../../../Configurations/Appservice";
import OrderDialog from "./components/OrderDialog";

const { Box, Grid, Typography, Card } = require("@mui/material");

const Test = (props) => {
  return <Typography>hello</Typography>;
};

const ConsumerDash = (props) => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [fundAllocation, setFundAllocation] = useState({});
  const [isLoading, loading] = useState(true);
  const [openOrderDialog, setOpenOrderDialog] = useState(false);

  useEffect(() => {
    loading(true);
    AppService.get("treat/api/v1/dashboard/consumer")
      .then((response) => {
        setWalletBalance(response.walletBalance || 0);
        setFundAllocation(response.fundAllocation || {});
      })
      .catch(() => {})
      .finally(() => {
        loading(false);
      });
  }, []);

  const _onClaimSuccess = (amount) => {
    let balance = walletBalance + amount;
    let fundAllocationCopy = { ...fundAllocation };
    fundAllocationCopy.amount = 0;
    setWalletBalance(balance);
    setFundAllocation(fundAllocationCopy);
  };

  const _onOrderComplete = (amount) => {
    setWalletBalance(walletBalance - amount);
    setOpenOrderDialog(false);
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - ${pxToRem(80)})`,
        mt: pxToRem(-8),
      }}
    >
      <OrderDialog
        open={openOrderDialog}
        onClose={() => setOpenOrderDialog(false)}
        onOrderComplete={_onOrderComplete}
      />
      <Grid container>
        <Grid item xs={6}>
          <Box>
            <WalletBalance balance={walletBalance} loading={isLoading} />
            <AllocatedAmount
              fundAllocation={fundAllocation}
              loading={isLoading}
              onSucess={_onClaimSuccess}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <OrderDetails />
        </Grid>
        <Grid item xs={9}>
          <TransactionCard />
        </Grid>
        <Grid item xs={3}>
          <ProfileCard onClick={() => setOpenOrderDialog(true)} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConsumerDash;

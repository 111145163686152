import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, TextField, Tooltip } from "@mui/material";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import DataTableBodyCell from "../DataTable/DataTableBodyCell";
import { useMemo } from "react";

// const data = [
//   {
//     id: 1,
//     firstName: "Brant",
//     lastName: "Brambell",
//     age: "bbrambell0@ameblo.jp",
//     status: "Male",
//     progress: "225.86.11.58",
//   },
//   {
//     id: 2,
//     firstName: "Roxanna",
//     lastName: "Louche",
//     age: "rlouche1@about.com",
//     status: "Female",
//     progress: "138.210.2.12",
//   },
//   {
//     id: 3,
//     firstName: "Gates",
//     lastName: "Ghelardi",
//     age: "gghelardi2@ycombinator.com",
//     status: "Female",
//     progress: "75.240.43.67",
//   },
//   {
//     id: 4,
//     firstName: "Florencia",
//     lastName: "Gullane",
//     age: "fgullane3@booking.com",
//     status: "Female",
//     progress: "114.13.192.246",
//   },
//   {
//     id: 5,
//     firstName: "Calley",
//     lastName: "Escreet",
//     age: "cescreet4@techcrunch.com",
//     status: "Genderqueer",
//     progress: "92.6.216.150",
//   },
//   {
//     id: 6,
//     firstName: "Charyl",
//     lastName: "Foskin",
//     age: "cfoskin5@businessinsider.com",
//     status: "Female",
//     progress: "237.111.213.95",
//   },
//   {
//     id: 7,
//     firstName: "Vita",
//     lastName: "Frankel",
//     age: "vfrankel6@samsung.com",
//     status: "Genderqueer",
//     progress: "0.15.144.51",
//   },
//   {
//     id: 8,
//     firstName: "Bartel",
//     lastName: "Proffer",
//     age: "bproffer7@woothemes.com",
//     status: "Male",
//     progress: "195.124.57.192",
//   },
//   {
//     id: 9,
//     firstName: "Debor",
//     lastName: "Smolan",
//     age: "dsmolan8@dailymail.co.uk",
//     status: "Female",
//     progress: "103.85.250.222",
//   },
//   {
//     id: 10,
//     firstName: "Ardyce",
//     lastName: "Prosser",
//     age: "aprosser9@icq.com",
//     status: "Female",
//     progress: "51.131.41.151",
//   },
//   {
//     id: 11,
//     firstName: "Palm",
//     lastName: "Swatridge",
//     age: "pswatridgea@istockphoto.com",
//     status: "Male",
//     progress: "35.224.1.198",
//   },
//   {
//     id: 12,
//     firstName: "Megen",
//     lastName: "Cozby",
//     age: "mcozbyb@blogger.com",
//     status: "Female",
//     progress: "231.213.185.249",
//   },
//   {
//     id: 13,
//     firstName: "Lenci",
//     lastName: "Matterdace",
//     age: "lmatterdacec@patch.com",
//     status: "Male",
//     progress: "2.242.15.117",
//   },
//   {
//     id: 14,
//     firstName: "Myra",
//     lastName: "Burger",
//     age: "mburgerd@exblog.jp",
//     status: "Female",
//     progress: "137.120.193.186",
//   },
//   {
//     id: 15,
//     firstName: "Xaviera",
//     lastName: "Eccersley",
//     age: "xeccersleye@exblog.jp",
//     status: "Female",
//     progress: "82.211.25.66",
//   },
//   {
//     id: 16,
//     firstName: "Damon",
//     lastName: "Kobu",
//     age: "dkobuf@gov.uk",
//     status: "Male",
//     progress: "34.59.204.213",
//   },
//   {
//     id: 17,
//     firstName: "Carrie",
//     lastName: "Doggrell",
//     age: "cdoggrellg@tumblr.com",
//     status: "Female",
//     progress: "63.157.134.187",
//   },
//   {
//     id: 18,
//     firstName: "Riannon",
//     lastName: "Hellis",
//     age: "rhellish@imageshack.us",
//     status: "Female",
//     progress: "203.162.142.168",
//   },
//   {
//     id: 19,
//     firstName: "Idalia",
//     lastName: "Whittock",
//     age: "iwhittocki@eepurl.com",
//     status: "Female",
//     progress: "22.85.151.99",
//   },
//   {
//     id: 20,
//     firstName: "Happy",
//     lastName: "Kingswoode",
//     age: "hkingswoodej@cpanel.net",
//     status: "Female",
//     progress: "137.79.11.244",
//   },
//   {
//     id: 21,
//     firstName: "Idalia",
//     lastName: "Punter",
//     age: "ipunterk@blogspot.com",
//     status: "Female",
//     progress: "237.109.111.66",
//   },
//   {
//     id: 22,
//     firstName: "Raychel",
//     lastName: "Orviss",
//     age: "rorvissl@list-manage.com",
//     status: "Female",
//     progress: "123.170.255.206",
//   },
//   {
//     id: 23,
//     firstName: "Viole",
//     lastName: "Ivashnyov",
//     age: "vivashnyovm@yale.edu",
//     status: "Female",
//     progress: "185.218.166.7",
//   },
//   {
//     id: 24,
//     firstName: "Aleen",
//     lastName: "Baake",
//     age: "abaaken@mozilla.com",
//     status: "Female",
//     progress: "170.6.45.106",
//   },
//   {
//     id: 25,
//     firstName: "Westleigh",
//     lastName: "Tenant",
//     age: "wtenanto@fc2.com",
//     status: "Male",
//     progress: "148.153.107.186",
//   },
// ];
// const columnHelper = createColumnHelper();
// const columnsData = [
//   columnHelper.accessor("firstName", {
//     header: "First Name",
//     // cell: (info) => info.getValue(),
//     // footer: (info) => info.column.id,
//   }),
//   columnHelper.accessor("lastName", {
//     header: "Last Name",
//     // header:" Last Name"
//     // id: "lastName",
//     // cell: (info) => info.getValue(),
//     // header: () => <span>Last Name</span>,
//     // footer: (info) => info.column.id,
//   }),
//   columnHelper.accessor("age", {
//     header: "Age",
//     // cell: (info) => info.renderValue(),
//     // footer: (info) => info.column.id,
//   }),
//   // columnHelper.accessor("visits", {
//   //   header: () => <span>Visits</span>,
//   //   footer: (info) => info.column.id,
//   // }),
//   columnHelper.accessor("status", {
//     header: "Status",
//     footer: (info) => info.column.id,
//   }),
//   columnHelper.accessor("progress", {
//     header: "Profile Progress",
//     footer: (info) => info.column.id,
//   }),
// ];

export default function WdTable({ columnsData, data }) {
  const columns = useMemo(() => columnsData, []);

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting: sorting,
      globalFilter: globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const { pageSize, pageIndex } = table.getState().pagination;

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        border: 1.5,
        borderRadius: 3,
        borderColor: "rgba(229,227,227,1)",
      }}
    >
      <Box sx={{ display: "flex", flex: 1, overflow: "auto" }}>
        <TableContainer
          aria-label="sticky table"
          sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        >
          <Table stickyHeader>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      sx={({ typography: { size, fontWeightBold } }) => ({
                        fontSize: size.xs,
                        fontWeight: fontWeightBold,
                        textTransform: "uppercase",
                        cursor: "pointer",
                      })}
                    >
                      {
                        {
                          desc: <SouthIcon sx={{ fontSize: 12 }} />,
                          asc: <NorthIcon sx={{ fontSize: 12 }} />,
                        }[header.column.getIsSorted() ?? null]
                      }
                      {header.isPlaceholder ? null : (
                        <Tooltip
                          placement="top"
                          title={`Sort with ${flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}`}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Tooltip>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <DataTableBodyCell key={cell.id}>
                      {console.log(cell)}
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        sx={{
          px: 2,
          py: 1,
          display: "flex",
          alignItems: "center",
          borderTop: 1,
          borderColor: "rgba(229,227,227,.5)",
          justifyContent: "space-between",
        }}
      >
        <TextField
          value={globalFilter}
          onChange={(event) => {
            setGlobalFilter(event.target.value);
          }}
          placeholder="Global Search"
          inputProps={{
            style: { height: 10 },
          }}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: data.length }]}
            component="div"
            count={table.getFilteredRowModel().rows.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            slotProps={{
              select: {
                inputProps: { "aria-label": "rows per page" },
                native: true,
              },
            }}
            onPageChange={(_, page) => {
              table.setPageIndex(page);
            }}
            onRowsPerPageChange={(e) => {
              const size = e.target.value ? Number(e.target.value) : 10;
              table.setPageSize(size);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

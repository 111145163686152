import pxToRem from "../../../Functions/pxToRem";
import borders from "../../basics/borders";
import colors from "../../basics/colors";

const { borderWidth } = borders;
const { light } = colors;

const tableCell = {
  styleOverrides: {
    root: {
      color: "red",
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
    },
  },
};

export default tableCell;

function collapseItem(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
  const { white, transparent, dark, grey } = palette;
  const { borderRadius } = borders;
  const { pxToRem, rgba } = functions;
  const { active } = ownerState;

  return {
    bgcolor: active ? rgba(white.main, 0.2) : transparent.main,
    width: "100%",
    color: "white.main",
    display: "flex",
    alignItems: "center",
    padding: `${pxToRem(3)} ${pxToRem(6)}`,
    margin: `${pxToRem(1.5)} ${pxToRem(9)}`,
    borderRadius: borderRadius.md,
    cursor: "pointer",
    [breakpoints.up("xl")]: {
      transition: transitions.create(["box-shadow", "background-color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },
    "&:hover, &:focus": {
      backgroundColor: rgba(white.main, 0.2),
    },
  };
}

function collapseIconBox(theme) {
  const { palette, transitions, borders, functions } = theme;

  const { white } = palette;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    color: white.main,
    borderRadius: borderRadius.md,
    display: "grid",
    placeItems: "center",
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    "& svg, svg g": {
      color: white.main,
    },
  };
}

function collapseText(theme, ownerState) {
  const { typography, transitions, breakpoints, functions } = theme;
  const { miniSidenav, active } = ownerState;

  const { size, fontWeightRegular, fontWeightLight } = typography;
  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(10),

    [breakpoints.up("xl")]: {
      opacity: miniSidenav ? 0 : 1,
      maxWidth: miniSidenav ? 0 : "100%",
      marginLeft: miniSidenav ? 0 : pxToRem(10),
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& span": {
      fontWeight: active ? fontWeightRegular : fontWeightLight,
      fontSize: size.sm,
      lineHeight: 0,
    },
  };
}

function collapseArrow(theme, ownerState) {
  const { palette, typography, transitions, breakpoints, functions } = theme;
  const { miniSidenav, open, active } = ownerState;

  const { white, dark } = palette;
  const { size } = typography;
  const { pxToRem, rgba } = functions;

  return {
    fontSize: `${size.lg} !important`,
    fontWeight: 700,
    marginBottom: pxToRem(-1),
    transform: open ? "rotate(0)" : "rotate(-180deg)",
    color: () => {
      return open || active ? white.main : rgba(white.main, 0.5);
    },
    transition: transitions.create(["color", "transform", "opacity"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      display: miniSidenav ? "none !important" : "block !important",
    },
  };
}

function menuItem(theme, ownerState) {
  const { palette, borders, functions, transitions } = theme;
  const { active, color, transparentSidenav, whiteSidenav, darkMode } = ownerState;

  const { transparent, white, grey } = palette;
  const { borderRadius } = borders;
  const { rgba } = functions;

  return {
    pl: 2,
    mt: 0.375,
    mb: 0.3,
    width: "100%",
    borderRadius: borderRadius.md,
    cursor: "pointer",
    backgroundColor: active ? "rgba(217,217,217,0.41)" : transparent.main,
    transition: transitions.create("background-color", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.sharp,
    }),

    "&:hover, &:focus": {
      backgroundColor: !active && rgba(white.main, 0.2),
    },
  };
}

function menuItemContent(theme, ownerState) {
  const { palette, typography, transitions, functions } = theme;
  const { miniSidenav, name, active } = ownerState;

  const { white } = palette;
  const { size, fontWeightRegular, fontWeightLight } = typography;
  const { pxToRem } = functions;

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    marginLeft: pxToRem(18),
    userSelect: "none",
    position: "relative",

    "& span": {
      color: white.main,
      fontWeight: active ? 500 : fontWeightLight,
      fontSize: size.sm,
      opacity: miniSidenav ? 0 : 1,
      transition: transitions.create(["opacity", "color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "&::before": {
      content: `"${name[0]}"`,
      color: white.main,
      fontWeight: fontWeightRegular,
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: pxToRem(-15),
      opacity: 1,
      borderRadius: "50%",
      fontSize: size.sm,
    },
  };
}

function menuItemText(theme, ownerState) {
  const { transitions, breakpoints } = theme;
  const { miniSidenav } = ownerState;

  return {
    [breakpoints.up("xl")]: {
      opacity: miniSidenav ? 0 : 1,
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },
  };
}

export {
  collapseItem,
  collapseIconBox,
  collapseText,
  collapseArrow,
  menuItem,
  menuItemContent,
  menuItemText,
};

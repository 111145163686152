import { Box, FormControl, Grid, InputLabel, TextField } from "@mui/material";
import WdForm from "../../../Components/WdForm";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  dismissLoader,
  showLoader,
  showSuccessMessage,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import WdSelect from "../../../Components/WdSelect";
import AppService from "../../../Configurations/Appservice";

const FundSettlement = (props) => {
  const [shopList, setShopList] = useState([]);
  const [shop, setShop] = useState("");
  const [walletDetails, setWalletDetails] = useState();
  const [amountTobePaid, setAmountTobePaid] = useState(0);
  const [settlingAmount, setSettlingAmount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateScreenTitle("Fund Settlement"));
    return () => dispatch(updateScreenTitle(""));
  }, []);

  useEffect(() => {
    dispatch(showLoader());
    AppService.get("treat/api/v1/coordinator/coordinatingShops")
      .then((response) => {
        setShopList(response);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  }, []);

  const _onShopChange = (event) => {
    dispatch(showLoader());
    setShop(event.target.value);
    const params = { shopId: event.target.value };
    AppService.get("treat/api/v1/shop/amountTobePaid", { params })
      .then((response) => {
        setWalletDetails(response);
        setAmountTobePaid(response.balance);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  const _onSubmit = (e) => {
    dispatch(showLoader());
    const params = { shopId: shop, amount: settlingAmount };
    AppService.post("treat/api/v1/shop/settlement", null, { params })
      .then((response) => {
        dispatch(showSuccessMessage("Settlement is successfull"));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });

    e.preventDefault();
  };

  return (
    <form onSubmit={_onSubmit}>
      <WdForm>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="service-label" required>
                Select Shop
              </InputLabel>
              <WdSelect
                label="Select Shop"
                fullWidth
                // loading={districtLoading}
                value={shop}
                onChange={_onShopChange}
              >
                {shopList}
              </WdSelect>
            </FormControl>
          </Grid>
          {walletDetails && (
            <>
              <Grid item xs={3}>
                <TextField label="Amount to be paid" fullWidth disabled value={amountTobePaid} />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Settling Amount"
                  fullWidth
                  disabled={!walletDetails}
                  value={settlingAmount}
                  onChange={(event) => {
                    setSettlingAmount(event.target.value);
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </WdForm>
    </form>
  );
};

export default FundSettlement;

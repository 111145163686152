import { Box, Grid } from "@mui/material";
import HierarchySelector from "../../../Components/HierarchySelector";
import ChipButton from "../../../Components/ChipButton";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  dismissLoader,
  showErroMessage,
  showLoader,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import WdTable from "../../../Components/Table/WdTable";
import ScreenBox from "../../../Components/ScreenBox";
import AppService from "../../../Configurations/Appservice";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper();
const columnsData = [
  columnHelper.accessor("name", {
    header: "Consumer Name",
  }),
  columnHelper.accessor("consumerNumber", {
    header: "Consumer Number",
  }),
  columnHelper.accessor("phoneNumber", {
    header: "Phone Number",
  }),
  columnHelper.accessor("hierarchyName", {
    header: "Hierarchy Name",
  }),
  columnHelper.accessor("hierarchyType", {
    header: "Hierarchy Type",
  }),
];

const ConsumerList = (props) => {
  const dispatch = useDispatch();
  const [district, setDistrict] = useState();
  const [area, setArea] = useState();
  const [branch, setBranch] = useState();
  const [list, setList] = useState([]);

  useEffect(() => {
    dispatch(updateScreenTitle("Consumer List"));
    return () => dispatch(updateScreenTitle(""));
  });

  const isHierarchySelected = () => {
    const hierarchyId = branch?.key || area?.key || district?.key;
    return hierarchyId != null && hierarchyId.length > 0;
  };

  const _search = () => {
    dispatch(showLoader());
    const hierarchyId = branch?.key || area?.key || district?.key;
    if (!hierarchyId) {
      dispatch(dismissLoader());
      dispatch(showErroMessage("Select any hierarchy"));
      return;
    }
    const params = { hierarchyId };
    AppService.get("treat/api/v1/consumer/serach", { params })
      .then((response) => {
        setList(response);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  return (
    <ScreenBox>
      <Grid container spacing={2} mb={2}>
        <HierarchySelector
          gridWidth={10 / 3}
          onDistrictChange={setDistrict}
          onAreaChange={setArea}
          onBranchChange={setBranch}
        />
        <Grid item xs={2}>
          <ChipButton fullWidth onClick={_search} disabled={!isHierarchySelected()}>
            Search
          </ChipButton>
        </Grid>
      </Grid>
      <WdTable columnsData={columnsData} data={list} />
    </ScreenBox>
  );
};

export default ConsumerList;

import pxToRem from "../../../Themes/Functions/pxToRem";

export const rootBox = {
  display: "flex",
  width: "100vw",
  height: "100vh",
  backgroundColor: "white.main",
  // alignItems: "center",
  // justifyContent: "center",
};

export const formPanel = {
  position: "relative",
  display: "flex",
  width: "50%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  bgcolor: "#A52E2E",
  borderTopRightRadius: pxToRem(180),
  borderBottomRightRadius: pxToRem(180),
};

export const rightPanel = {
  display: "flex",
  width: "50%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  // bgcolor: "red",
  // px: 20,
};

export const formCard = {
  width: "50%",
  p: 4,
};

export const header = {
  mb: 2,
  justifyContent: "center",
  alignItems: "center",
};

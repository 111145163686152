import { Field, Form, Formik } from "formik";
import WdForm from "../../../../Components/WdForm";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import WdSelect from "../../../../Components/WdSelect";
import { object, string } from "yup";
import { useEffect, useState } from "react";
import HierarchySelector from "../../../../Components/HierarchySelector";
import { AREA, BRANCH, DISTRICT } from "../../../../Configurations/AppConfig";
import { useDispatch } from "react-redux";
import {
  dismissLoader,
  showLoader,
  showSuccessMessage,
} from "../../../../LayoutContainers/AppLayout/redux/AppSlice";
import AppService from "../../../../Configurations/Appservice";
import { COORDINATOR_TYPE, USER_TYPE } from "../../../../Configurations/AppConstants";

const initialValues = {
  name: "",
  phoneNumber: "",
  password: "",
  address: "",
  email: "",
};

const validationSchema = object({
  name: string().required("Name is Mandatory"),
  phoneNumber: string().required("Phone Number is Mandatory"),
  password: string().required("Password is Mandatory"),
  address: string().required("Address is Mandatory"),
});

export default function ShopOwnerForm(props) {
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("");

  const [district, setDistrict] = useState();
  const [area, setArea] = useState();
  const [branch, setBranch] = useState();

  const [userHierarchy, setUserHierarchy] = useState();

  const [hierarchyList, setHierarchyList] = useState([]);

  const [shop, setShop] = useState();
  const [shopLoading, setShopLoading] = useState(false);
  const [shopList, setShopList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLoader());
    AppService.get("treat/api/v1/coordinator/types")
      .then((response) => {
        const list = response.map((item) => ({ key: item, value: item }));
        setTypes(list);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  }, []);

  useEffect(() => {
    if (!type) return;

    if (type === COORDINATOR_TYPE.DISTRICT_COORDINATOR) {
      setHierarchyList([DISTRICT]);
    } else if (type === COORDINATOR_TYPE.AREA_COORDINATOR) {
      setHierarchyList([DISTRICT, AREA]);
    } else if (type === COORDINATOR_TYPE.BRANCH_COORDINATOR) {
      setHierarchyList([DISTRICT, AREA, BRANCH]);
    }
  }, [type]);

  const _onSubmit = (values, { resetForm }) => {
    dispatch(showLoader());
    const body = {
      ...values,
      phoneNumber: "+91" + values.phoneNumber,
      coordinatorType: type,
      hierarchyId: userHierarchy.key,
      shopId: shop,
      managedBy: type,
    };
    // console.log(body);
    // alert(JSON.stringify(body));
    // return;

    AppService.post("treat/api/v1/shop/shopOwner", body)
      .then((response) => {
        resetForm();
        dispatch(showSuccessMessage("Shop owner created successfully"));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  const _onDistrictChange = (district) => {
    setDistrict(district);
    type === COORDINATOR_TYPE.DISTRICT_COORDINATOR && _loadShop(district.key);
  };

  const _onAreaChange = (area) => {
    setArea(area);
    type === COORDINATOR_TYPE.AREA_COORDINATOR && _loadShop(area.key);
  };

  const _onBranchChange = (branch) => {
    setBranch(branch);
    type === COORDINATOR_TYPE.BRANCH_COORDINATOR && _loadShop(branch.key);
  };

  const _loadShop = (hierarchyId) => {
    setShopLoading(true);
    setShopList([]);
    const params = { hierarchyId };
    AppService("treat/api/v1/shop/byHierarchy", { params })
      .then((response) => {
        setShopList(response);
      })
      .catch(() => {})
      .finally(() => {
        setShopLoading(false);
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={_onSubmit} validationSchema={validationSchema}>
      {({ errors, isValid, touched, dirty }) => (
        <Form>
          <WdForm>
            <Typography color="primary" variant="subtitle2" mb={2}>
              Basic Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box>
                  <Field
                    required
                    name="name"
                    label="Name"
                    placeholder="User Name"
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.name) && Boolean(touched.name)}
                    helperText={Boolean(touched.name) && errors.name}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="phoneNumber"
                    label="Phone Number"
                    placeholder="Phone Number"
                    required
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.phoneNumber) && Boolean(touched.phoneNumber)}
                    helperText={Boolean(touched.phoneNumber) && errors.phoneNumber}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <Typography fontSize={14} sx={{ fontWeight: "500" }}>
                            +91
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="password"
                    label="Password"
                    placeholder="Password"
                    required
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.password) && Boolean(touched.password)}
                    helperText={Boolean(touched.password) && errors.password}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="email"
                    label="Email"
                    placeholder="Email"
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.email) && Boolean(touched.email)}
                    helperText={Boolean(touched.email) && errors.email}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="address"
                    label="Address"
                    required
                    placeholder="Address"
                    fullWidth
                    as={TextField}
                    multiline
                    error={Boolean(errors.address) && Boolean(touched.address)}
                    helperText={Boolean(touched.address) && errors.address}
                  />
                </Box>
              </Grid>

              <HierarchySelector onBranchChange={setUserHierarchy} />
            </Grid>

            <Typography color="primary" variant="subtitle2" my={2}>
              Shop & Hierarchy Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="service-label" required>
                      Managed By
                    </InputLabel>
                    <WdSelect
                      label="Managed By"
                      fullWidth
                      // required
                      value={type}
                      onChange={(event) => {
                        setType(event.target.value);
                        setShopList([]);
                        setShop();
                      }}
                    >
                      {types}
                    </WdSelect>
                  </FormControl>
                </Box>
              </Grid>
              <HierarchySelector
                list={hierarchyList}
                onDistrictChange={_onDistrictChange}
                onAreaChange={_onAreaChange}
                onBranchChange={_onBranchChange}
              />
              <Grid item xs={3}>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="service-label" required>
                      Shop
                    </InputLabel>
                    <WdSelect
                      label="Shop"
                      fullWidth
                      loading={shopLoading}
                      // required
                      value={shop}
                      onChange={(event) => {
                        setShop(event.target.value);
                      }}
                    >
                      {shopList}
                    </WdSelect>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </WdForm>
        </Form>
      )}
    </Formik>
  );
}

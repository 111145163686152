import { Backdrop } from "@mui/material";
import { Watch } from "react-loader-spinner";

const WDLoader = (props) => {
  return (
    <Backdrop
      {...props}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      // onClick={handleClose}
    >
      <Watch
        height="60"
        width="60"
        radius="48"
        color="#ffffff"
        ariaLabel="watch-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </Backdrop>
  );
};

export default WDLoader;

import { Box, Card, CircularProgress, Typography } from "@mui/material";
import pxToRem from "../../../../Themes/Functions/pxToRem";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";

const ShopDetails = ({ shopDetails, loading }) => {
  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "row",
          height: `calc((100vh - ${pxToRem(144)})*0.3)`,
          margin: pxToRem(16),
          mr: 0,
          p: 1,
          px: 2,
          cursor: "pointer",
          boxShadow:
            "-4px -5px 4px 0 rgba(217, 217, 233,0.3),4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
        }}
        // bgcolor="yellow"
      >
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Typography color="#00796B" variant="h4">
            My Shop Details
          </Typography>
          <Box sx={{ display: "flex", flex: 1, flexDirection: "column", mt: 2 }}>
            {!loading && (
              <>
                <Typography sx={{ fontWeight: 500 }}>{shopDetails.name || ""}</Typography>
                <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                  {shopDetails.address || ""}
                </Typography>
              </>
            )}
            {loading && <CircularProgress sx={{ mx: 2 }} />}
          </Box>
        </Box>
        <Box
          sx={{
            p: 2,
            pr: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              background: "rgb(51,184,57)",
              background:
                "linear-gradient(41deg, rgba(51,184,57,1) 21%, rgba(11,15,94,1) 100%, rgba(69,252,233,1) 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
              borderRadius: 2,
              boxShadow:
                "-4px -5px 4px 0 rgba(217, 217, 233,0.3),4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
            }}
          >
            <StorefrontOutlinedIcon sx={{ fontSize: 60, color: "#ffffff" }} />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default ShopDetails;

import { Box } from "@mui/material";
import pxToRem from "../Themes/Functions/pxToRem";

export default function ScreenBox({ children }) {
  return (
    <Box
      sx={{
        position: "relative",
        height: `calc(100vh - ${pxToRem(80)})`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </Box>
  );
}

import { Box, Typography } from "@mui/material";
import MenuItem from "./MenuItem";
import adminRoute from "../../../../Configurations/routes/AdminRoute";
import { memo, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import uuid from "react-uuid";
import getAppRoutes from "../../../../Configurations/routes";
import { CONSUMER_MENU, COORDINATOR_MENU, SHOPOWNER_MENU } from "../../LayoutData";
import { AUTH_KEY, USER_ROLES, USER_TYPES } from "../../../../Configurations/AppConfig";

let MENU = [
  // {
  //   label: "Dashboard",
  //   route: "null",
  //   type: "H",
  // },
  // {
  //   label: "My Dashboard",
  //   route: "/coordinator/dash",
  //   type: "M",
  // },
  // {
  //   label: "Consumer Management",
  //   route: "null",
  //   type: "H",
  // },
  // {
  //   label: "Consumers",
  //   route: "/consumers",
  //   type: "M",
  // },
  // {
  //   label: "New Consumer",
  //   route: "/newConsumer",
  //   type: "M",
  // },
  // {
  //   label: "Fund Management",
  //   route: "null",
  //   type: "H",
  // },
  // {
  //   label: "Fund Allocation",
  //   route: "/fundAllocation",
  //   type: "M",
  // },
  // {
  //   label: "Allocation Details",
  //   route: "/fundAllocationDetails",
  //   type: "M",
  // },
  // {
  //   label: "Shop Management",
  //   route: "null",
  //   type: "H",
  // },
  // {
  //   label: "New Shop",
  //   route: "/newShop",
  //   type: "M",
  // },
  // {
  //   label: "Shop List",
  //   route: "/shopList",
  //   type: "M",
  // },
  // {
  //   label: "New Owner",
  //   route: "/newShopOwner",
  //   type: "M",
  // },
  // {
  //   label: "Coordinator Management",
  //   route: "null",
  //   type: "H",
  // },
  // {
  //   label: "New Coordinator",
  //   route: "/newCoordinator",
  //   type: "M",
  // },
  // {
  //   label: "Coordinator List",
  //   route: "/coordinatorList",
  //   type: "M",
  // },
  // {
  //   label: "Hierarchy Management",
  //   route: "null",
  //   type: "H",
  // },
  // {
  //   label: "New Hierarchy",
  //   route: "/newHierarchy",
  //   type: "M",
  // },
];

const DrawerList = ({ open, active, minSideNav }) => {
  const authInfo = localStorage.getItem(AUTH_KEY) || sessionStorage.getItem(AUTH_KEY);
  if (authInfo) {
    const auth = JSON.parse(authInfo);
    const userType = auth?.principal?.type || "";
    if (userType === USER_TYPES.COORDINATOR) {
      MENU = COORDINATOR_MENU;
    } else if (userType === USER_TYPES.CONSUMER) {
      MENU = CONSUMER_MENU;
    } else if (userType === USER_TYPES.SHOP_OWNER) {
      MENU = SHOPOWNER_MENU;
    }
  }

  const _renderMenu = () => {
    return MENU.map((item) => {
      if (item.type === "H") {
        return (
          <Box
            px={1}
            // mb={0.5}
            mt={1}
            sx={(theme) => {
              return {
                opacity: open ? 1 : 0,
                transition: theme.transitions.create(["opacity"], {
                  easing: theme.transitions.easing.easeInOut,
                  duration: theme.transitions.duration.standard,
                }),
              };
            }}
            key={uuid()}
          >
            <Typography fontSize={12} color="white.main" variant="h6">
              {item.label.toUpperCase()}
            </Typography>
          </Box>
        );
      } else if (item.type === "M") {
        return (
          <NavLink to={item.route} key={item.route} style={{ textDecoration: "none" }}>
            {console.log("{  }")}
            <MenuItem open={open} name={item.label} route={item.route} />
          </NavLink>
        );
      }
    });
  };

  return (
    <Box pb={16} sx={{ overflow: "scroll" }}>
      {_renderMenu()}
    </Box>
  );
};

export default memo(DrawerList);

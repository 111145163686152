import { Box, Typography } from "@mui/material";
import ChipButton from "../../Components/ChipButton";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5" fontSize={50} color="primary">
        Page Not Found
      </Typography>
      <ChipButton style={{ m: 2 }} onClick={() => navigate(-1)}>
        Back to Home
      </ChipButton>
    </Box>
  );
}

import { Box, ListItem, ListItemText, Tooltip } from "@mui/material";
import { menuItem, menuItemContent, menuItemText } from "./Styles";
import { useLocation } from "react-router-dom";

const MenuItem = ({ open, name, route }) => {
  const location = useLocation();
  const { pathname } = location;
  let active = pathname === route;
  return (
    <Box sx={{ px: 1, my: 0.3 }}>
      <Tooltip title={open ? "" : name}>
        <ListItem sx={(theme) => menuItem(theme, { active: active })}>
          <Box
            sx={(theme) =>
              menuItemContent(theme, {
                active: active,
                name,
              })
            }
          >
            <ListItemText
              primary={name}
              sx={(theme) =>
                menuItemText(theme, {
                  miniSidenav: !open,
                })
              }
            />
          </Box>
        </ListItem>
      </Tooltip>
    </Box>
  );
};

export default MenuItem;

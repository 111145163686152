import { Avatar, Box, Card, Grid, Paper, Typography } from "@mui/material";
import { StringToColor } from "../../../../Configurations/JsUtil";

const MyOrderCard = (props) => {
  return (
    <Paper
      sx={{ my: 1, px: 1, py: 2, display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Avatar sx={{ bgcolor: StringToColor("H") }}>H</Avatar>
      <Grid container ml={1} alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h6" fontSize={14}>
            Kaitheri Store
          </Typography>
          <Typography color="#ABA9A9" fontSize={14}>
            STORE1003
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography fontSize={14}>12/04/2023</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" fontSize={14} gutterBottom>
            575.00 ₹
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MyOrderCard;

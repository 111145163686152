import { Icon } from "@mui/material";
import WDAvatar from "../../MaterialComponents/WDAvatar";
import profilePicture from "../../assets/images/team-5.jpg";
import { RiHandCoinFill, RiHandHeartFill } from "react-icons/ri";
import { BsTicketPerforatedFill, BsWalletFill } from "react-icons/bs";
import { FaFileInvoiceDollar, FaUserCog } from "react-icons/fa";
import { GiSettingsKnobs } from "react-icons/gi";

const agentRoute = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "ADM-Dash",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Customer Dashboard",
        key: "customer",
        route: "ADM-Dash/customer",
      },
      {
        name: "Partner Dashboard",
        key: "partner",
        route: "ADM-Dash/partner",
      },
    ],
  },
  {
    type: "collapse",
    name: "Customers",
    key: "ADM-Customer",
    icon: <Icon fontSize="medium">group-minus</Icon>,
    collapse: [
      {
        name: "Users",
        key: "adm-customer-users",
        route: "ADM-Customer/adm-customer-users",
      },
      {
        name: "Pending Tickets",
        key: "pending-customer-tickets",
        route: "ADM-Customer/pending-customer-tickets",
      },
    ],
  },
  {
    type: "collapse",
    name: "Partners",
    key: "ADM-Partner",
    icon: <Icon fontSize="medium">handshake</Icon>,
    collapse: [
      {
        name: "Users",
        key: "adm-partner-users",
        route: "ADM-Partner/adm-partner-users",
      },
      {
        name: "Pending Tickets",
        key: "pending-tickets",
        route: "ADM-Partner/pending-tickets",
      },
    ],
  },
  {
    type: "collapse",
    name: "Ticket",
    key: "ADM-Ticket",
    icon: <BsTicketPerforatedFill />,
    collapse: [
      {
        name: "All Tickets",
        key: "pending-tickets",
        route: "ADM-Ticket/pending-tickets",
      },
    ],
  },
  {
    type: "collapse",
    name: "Configurations",
    key: "ADM-APPCONFIG",
    icon: <GiSettingsKnobs />,
    collapse: [
      {
        name: "Reset Password",
        key: "reset_password",
        route: "ADM-APPCONFIG/reset_password",
      },
    ],
  },
  // {
  //   type: "collapse",
  //   name: "User Management",
  //   key: "ADM-USER-MGMNT",
  //   icon: <FaUserCog />,
  //   collapse: [
  //     {
  //       name: "Change Password",
  //       key: "change-password",
  //       route: "ADM-USER-MGMNT/change-password",
  //     },
  //   ],
  // },
];

export default agentRoute;

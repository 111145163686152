export const CUSTOMER_ORG_TYPE = "CUSTOMER";
export const PARTNER_ORG_TYPE = "PARTNER";
export const ADMIN_ORG_TYPE = "ADMIN_ORG_TYPE";

export const ADMIN_USER_TYPE = "ADMIN_ORG";
export const CUSTOMER_USER_TYPE = "CUSTOMER_ORG";
export const PARTNER_USER_TYPE = "PARTNER_ORG";

export const DISTRICT = "DISTRICT";
export const AREA = "AREA";
export const BRANCH = "BRANCH";

export const AUTH_KEY = "treat_auth";

export const USER_ROLES = {
  ADMIN: "ADMIN",
  STATE_ADMIN: "STATE_ADMIN",
  STATE_COORDINATOR: "STATE_COORDINATOR",
  DISTRICT_COORDINATOR: "DISTRICT_COORDINATOR",
  DISTRICT_ADMIN: "DISTRICT_ADMIN",
  AREA_ADMIN: "AREA_ADMIN",
  AREA_COORDINATOR: "AREA_COORDINATOR",
  BRANCH_ADMIN: "BRANCH_ADMIN",
  BRANCH_COORDINATOR: "BRANCH_COORDINATOR",
};

export const USER_TYPES = {
  COORDINATOR: "COORDINATOR",
  PUBLIC_USER: "PUBLIC_USER",
  CONSUMER: "CONSUMER",
  SHOP_OWNER: "SHOP_OWNER",
};

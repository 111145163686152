export const COORDINATOR_MENU = [
  {
    label: "Dashboard",
    route: "null",
    type: "H",
  },
  {
    label: "My Dashboard",
    route: "/coordinator/dash",
    type: "M",
  },
  {
    label: "Consumer Management",
    route: "null",
    type: "H",
  },
  {
    label: "Consumers",
    route: "/consumers",
    type: "M",
  },
  {
    label: "New Consumer",
    route: "/newConsumer",
    type: "M",
  },
  {
    label: "Fund Management",
    route: "null",
    type: "H",
  },
  {
    label: "Fund Allocation",
    route: "/fundAllocation",
    type: "M",
  },
  {
    label: "Allocation Details",
    route: "/fundAllocationDetails",
    type: "M",
  },
  {
    label: "Fund Transfer",
    route: "/fundTransfer",
    type: "M",
  },
  {
    label: "Add SponsorShip",
    route: "/newSponsorShip",
    type: "M",
  },
  {
    label: "Fund Settlement",
    route: "/fundSettlement",
    type: "M",
  },
  {
    label: "Shop Management",
    route: "null",
    type: "H",
  },
  {
    label: "New Shop",
    route: "/newShop",
    type: "M",
  },
  {
    label: "Shop List",
    route: "/shopList",
    type: "M",
  },
  {
    label: "New Owner",
    route: "/newShopOwner",
    type: "M",
  },
  {
    label: "Coordinator Management",
    route: "null",
    type: "H",
  },
  {
    label: "New Coordinator",
    route: "/newCoordinator",
    type: "M",
  },
  {
    label: "Coordinator List",
    route: "/coordinatorList",
    type: "M",
  },
  {
    label: "Hierarchy Management",
    route: "null",
    type: "H",
  },
  {
    label: "New Hierarchy",
    route: "/newHierarchy",
    type: "M",
  },
  {
    label: "Sponsorship",
    route: "null",
    type: "H",
  },
  {
    label: "New Sponsor",
    route: "/newSponsor",
    type: "M",
  },
  {
    label: "Configurations",
    route: "null",
    type: "H",
  },
  {
    label: "Configuration",
    route: "/userConfiguration",
    type: "M",
  },
];

export const CONSUMER_MENU = [
  {
    label: "Dashboard",
    route: "null",
    type: "H",
  },
  {
    label: "My Dashboard",
    route: "/coordinator/dash",
    type: "M",
  },
  {
    label: "Consumer Management",
    route: "null",
    type: "H",
  },
  {
    label: "Consumers",
    route: "/consumers",
    type: "M",
  },
  {
    label: "New Consumer",
    route: "/newConsumer",
    type: "M",
  },
];

export const SHOPOWNER_MENU = [
  {
    label: "Dashboard",
    route: "null",
    type: "H",
  },
  {
    label: "Shop Management",
    route: "null",
    type: "H",
  },
  {
    label: "New Shop",
    route: "/newShop",
    type: "M",
  },
  {
    label: "Shop List",
    route: "/shopList",
    type: "M",
  },
  {
    label: "New Owner",
    route: "/newShopOwner",
    type: "M",
  },
];

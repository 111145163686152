import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  dismissLoader,
  showLoader,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { Field, Form, Formik } from "formik";
import uuid from "react-uuid";
import ChipButton from "../../../Components/ChipButton";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { RotatingLines } from "react-loader-spinner";
import WdSelect from "../../../Components/WdSelect";
import AppService from "../../../Configurations/Appservice";
import WdForm from "../../../Components/WdForm";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CoordinatorForm from "./panels/CoordinatorForm";
import ShopOwnerForm from "./panels/ShopOwnerForm";
import ConsumerForm from "./panels/ConsumerForm";

const initialValues = {
  name: "",
  phoneNumber: "",
  password: "",
  address: "",
  userType: "",
  hierarchyId: "",
  email: "",
};

const validationSchema = object({
  name: string().required("Name is Mandatory"),
  phoneNumber: string().required("Phone Number is Mandatory"),
  password: string().required("Password is Mandatory"),
  address: string().required("Address is Mandatory"),
  userType: string().required("Select User Type"),
  hierarchyId: string().required("Select Branch"),
});

export default function NewUser() {
  const [types, setTypes] = useState([]);
  const dispatch = useDispatch();

  const [type, setType] = useState("");

  useEffect(() => {
    dispatch(updateScreenTitle("New User"));
    return () => dispatch(updateScreenTitle(""));
  });

  // useEffect(() => {
  //   dispatch(showLoader());
  //   AppService.get("treat/api/v1/hierarchy/types")
  //     .then((response) => {
  //       const types = response.map((item) => ({ key: item, value: item }));
  //       console.log(types);
  //       setTypes(types);
  //     })
  //     .catch(() => {})
  //     .finally(() => {
  //       dispatch(dismissLoader());
  //     });
  // }, []);

  const _onSubmit = (values) => {
    alert("it works!");
  };

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="New Shop Owner" value="1" />
            <Tab label={<Typography variant="button">NEW COORDINATOR</Typography>} value="2" />
            <Tab label="New Consumer" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <ShopOwnerForm />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <CoordinatorForm />
        </TabPanel>
        <TabPanel value="3" sx={{ p: 0 }}>
          <ConsumerForm />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

import boxShadows from "../../basics/boxShadows";
import borders from "../../basics/borders";
import colors from "../../basics/colors";
import rgba from "../../../Functions/rgba";

const { black, white } = colors;
const { borderWidth, borderRadius } = borders;
const { md, xl } = boxShadows;

const card = {
  styleOverrides: {
    root: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minWidth: 0,
      wordWrap: "break-word",
      backgroundColor: white.main,
      backgroundClip: "border-box",
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
      borderRadius: borderRadius.xl,
      boxShadow: xl,
      overflow: "visible",
    },
  },
};

export default card;

import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "appSlice",
  initialState: {
    screenTitle: "",
    value: 0,
    miniSidenav: true,
    showLoader: false,
    loaderText: "",
    showErrorMessage: false,
    successMessage: {},
    errorMessage: {},
    auth: {},
  },
  reducers: {
    toggleSideNav: (state, action) => {
      state.miniSidenav = action.payload;
    },
    showLoader: (state, action) => {
      state.showLoader = true;
      state.loaderText = action.payload;
    },
    dismissLoader: (state) => {
      state.showLoader = false;
      state.loaderText = "";
    },
    showSuccessMessage: (state, action) => {
      state.successMessage = { message: action.payload, show: true };
    },
    dismissSuccessMessage: (state) => {
      state.successMessage = {};
    },
    showErroMessage: (state, action) => {
      state.errorMessage = { message: action.payload, show: true };
    },
    dismissErrorMessage: (state) => {
      state.errorMessage = {};
    },
    updateScreenTitle: (state, action) => {
      state.screenTitle = action.payload;
    },
    updateAuthInfo: (state, action) => {
      state.auth = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleSideNav,
  showLoader,
  dismissLoader,
  showSuccessMessage,
  dismissSuccessMessage,
  showErroMessage,
  dismissErrorMessage,
  updateScreenTitle,
  updateAuthInfo,
} = appSlice.actions;

export default appSlice.reducer;

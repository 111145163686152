import { Box, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dismissLoader,
  showLoader,
  showSuccessMessage,
  updateScreenTitle,
} from "../../LayoutContainers/AppLayout/redux/AppSlice";
import WdForm from "../../Components/WdForm";
import SearchIcon from "@mui/icons-material/Search";
import Spacer from "../../Components/Spacer";
import AppService from "../../Configurations/Appservice";
import uuid from "react-uuid";
import FundAllocationCard from "./panels/FundAllocationCard";

export default function FundAllocation(props) {
  const [consumerDetails, setConsumerDetails] = useState();
  const [fundAllocation, setFundAllocation] = useState();
  const [consumerId, setConsumerId] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateScreenTitle("Fund Allocation"));
    return () => dispatch(updateScreenTitle(""));
  }, []);

  const _onSubmit = (e) => {
    dispatch(showLoader());
    const payload = {
      consumerId: consumerDetails.id,
      allocationMap: fundAllocation,
    };

    AppService.post("treat/api/v1/fundAllocation", payload)
      .then((response) => {
        dispatch(showSuccessMessage("Fund allocation successfull"));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
    e.preventDefault();
  };

  const _loadFundAllocationDetails = (consumer) => {
    const params = { customerId: consumer.id };
    AppService.get("treat/api/v1/fundAllocation/byCustomer", { params })
      .then((response) => {
        // alert(JSON.stringify(response));
        setFundAllocation(response);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  const _searchConsumer = () => {
    dispatch(showLoader());
    setConsumerDetails();
    setFundAllocation();
    const params = { consumerIdentity: consumerId };
    AppService.get("treat/api/v1/consumer/getByIdentity", { params })
      .then((response) => {
        setConsumerDetails(response);
        _loadFundAllocationDetails(response);
      })
      .catch(() => {
        dispatch(dismissLoader());
      });
  };

  const UserDetails = () => {
    const { name, address, phoneNumber, consumerNumber } = consumerDetails || {};
    return (
      <Grid container>
        <Grid item xs={3}>
          <Box>
            <Typography variant="h6" fontSize={16}>
              Name
            </Typography>
            <Typography fontSize={16}>{name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Typography variant="h6" fontSize={16}>
              Consumer Number
            </Typography>
            <Typography fontSize={16}>{consumerNumber}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Typography variant="h6" fontSize={16}>
              Phone Number
            </Typography>
            <Typography fontSize={16}>{phoneNumber}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Typography variant="h6" fontSize={16}>
              Address
            </Typography>
            <Typography fontSize={16}>{address}</Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const FundAllocations = () => {
    if (!fundAllocation) return null;

    return (
      <Grid container spacing={3}>
        {fundAllocation.map((allocation) => {
          return (
            <Grid item xs={3} key={allocation.id}>
              <FundAllocationCard allocation={allocation} />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <WdForm noForm>
      <Typography color="primary" variant="subtitle2" fontSize={14} mb={2}>
        Consumer Details
      </Typography>
      <TextField
        label="Consumer Id"
        placeholder="Consumer Id | Phone Number"
        value={consumerId}
        onChange={(event) => {
          setConsumerId(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={_searchConsumer}>
                <SearchIcon fontSize="small" color="primary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Spacer />
      {consumerDetails && <UserDetails />}
      {fundAllocation && (
        <>
          <Typography color="primary" variant="subtitle2" fontSize={14} my={2}>
            Fund Allocation Details
          </Typography>
          <FundAllocations />
        </>
      )}
      <Spacer />
    </WdForm>
  );
}

import { Chip } from "@mui/material";
import pxToRem from "../Themes/Functions/pxToRem";

const ChipButton = (props) => {
  const { children, style, fullWidth } = props;
  return (
    <Chip
      clickable
      label={children.toUpperCase()}
      color="primary"
      sx={{
        px: 2,
        fontWeight: "bold",
        fontSize: 12,
        borderRadius: pxToRem(10),
        height: pxToRem(43),
        minWidth: pxToRem(130),
        width: fullWidth ? "100%" : null,
        // backgroundColor: "green",

        // mx: 1,
        // backgroundColor: "#ffffff",
        // color: "primary.main",
        ...style,
      }}
      {...props}
    />
  );
};

export default ChipButton;

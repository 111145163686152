import { forwardRef } from "react";

import ButtonRoot from "./ButtonRoot";

const WDButton = forwardRef(
  ({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => {
    // const [controller] = useMaterialUIController();
    // const { darkMode } = controller;
    const darkMode = false;

    return (
      <ButtonRoot
        {...rest}
        ref={ref}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      >
        {children}
      </ButtonRoot>
    );
  }
);

// Declaring default props for MDButton
WDButton.defaultProps = {
  color: "primary",
  variant: "contained",
  size: "medium",
  circular: false,
  iconOnly: false,
};

export default WDButton;

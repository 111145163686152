import { Icon } from "@mui/material";
import { BsTicketPerforatedFill, BsWalletFill } from "react-icons/bs";
import { GiSettingsKnobs } from "react-icons/gi";

const customerRoute = [
  // { type: "title", title: "Dashboard" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "cst",
    noCollapse: true,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Dashboard",
        key: "dash",
        route: "cst/dash",
      },
    ],
  },
  // { type: "title", title: "Ticket" },
  {
    type: "collapse",
    name: "Ticket",
    key: "ADM-Ticket",
    noCollapse: true,
    icon: <BsTicketPerforatedFill />,
    collapse: [
      {
        name: "My Tickets",
        key: "pending-tickets",
        route: "ADM-Ticket/pending-tickets",
      },
      {
        name: "New Ticket",
        key: "new-ticket",
        route: "ADM-Ticket/new-ticket",
      },
    ],
  },
  // { type: "title", title: "Wallet" },
  {
    type: "collapse",
    name: "Wallet",
    key: "wallet",
    icon: <BsWalletFill />,
    noCollapse: true,
    collapse: [
      {
        name: "Invoices",
        key: "my-invoices",
        route: "wallet/my-invoices",
      },
      {
        name: "My Wallet",
        key: "my-wallet",
        route: "wallet/my-wallet",
      },
      // {
      //   name: "Pending Payments",
      //   key: "pending-customer-payments",
      //   route: "wallet/pending-customer-payments",
      // },
      {
        name: "New Payment Method",
        key: "new_customer_payment_method",
        route: "wallet/new_customer_payment_method",
      },
      {
        name: "Payment Methods",
        key: "customer-payment-methods",
        route: "wallet/customer-payment-methods",
      },
    ],
  },
  {
    type: "collapse",
    name: "Configurations",
    key: "ADM-APPCONFIG",
    icon: <GiSettingsKnobs />,
    collapse: [
      {
        name: "Reset Password",
        key: "reset_password",
        route: "ADM-APPCONFIG/reset_password",
      },
    ],
  },
];

export default customerRoute;

import axios from "axios";
import store from "../store";
import { showErroMessage } from "../../LayoutContainers/AppLayout/redux/AppSlice";
import { AUTH_KEY } from "../AppConfig";

const baseURl = process.env.REACT_APP_API_BASE_URL;
const AppService = axios.create({
  // baseURL: "http://192.168.1.8:8080",
  baseURL: baseURl,
});

AppService.interceptors.response.use(
  (response) => {
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error);
    let errorMessage = error?.response?.data?.message || "something went wrong";
    store.dispatch(showErroMessage(errorMessage));
    return Promise.reject(errorMessage);
  }
);

AppService.interceptors.request.use(function (config) {
  let authInfo = sessionStorage.getItem(AUTH_KEY);
  if (authInfo != null) {
    const token = JSON.parse(authInfo).accessToken;
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});

export default AppService;

import { Box, FormControlLabel, FormGroup, Paper, Switch, Typography } from "@mui/material";
import AppService from "../../../Configurations/Appservice";
import { useDispatch } from "react-redux";
import {
  dismissLoader,
  showLoader,
  showSuccessMessage,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";

let value = false;
const RecursiveSearchCard = ({ recursiveSearch, onUpdate }) => {
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(showLoader());
    const params = { value: event.target.checked };
    value = event.target.checked;
    AppService.put("treat/api/v1/userConfiguration/recursiveSearch", null, { params })
      .then((response) => {
        onUpdate(value);
        dispatch(showSuccessMessage("Configuration updated successfully"));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  return (
    <Box>
      <Paper sx={{ p: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="subtitle">Deep Search</Typography>
        <FormGroup>
          <FormControlLabel
            onChange={handleChange}
            control={<Switch />}
            label={recursiveSearch ? "ON" : "OFF"}
            checked={recursiveSearch}
            labelProps={{
              fontSize: 12,
            }}
          />
        </FormGroup>
      </Paper>
    </Box>
  );
};

export default RecursiveSearchCard;

import { Avatar, Box, Chip, Grid, Typography } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import moment from "moment";

const _parseDate = (date) => {
  var parsedDate = moment(date);
  return parsedDate.format("Do MMMM YYYY");
};
const TransactionCardItem = ({ item }) => {
  const { type, amount, transactionNumber, refernce, createdAt, subType } = item;
  const bgColor = type === "CREDIT" ? "#2E7D32" : "#E53935";
  return (
    <Box
      my={2}
      sx={{
        borderBottom: 1,
        pb: 1,
        borderColor: "#ECEFF1",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ backgroundColor: bgColor }}>
        {type === "CREDIT" ? <CallReceivedIcon /> : <ArrowOutwardIcon />}
      </Avatar>
      {/* <Avatar sx={{ backgroundColor: "#E53935" }}>
        <ArrowOutwardIcon />
      </Avatar> */}
      <Grid container ml={2}>
        <Grid item xs={4}>
          <Typography fontSize={14} variant="h6">
            {type === "CREDIT" ? "RECIEVED" : "PAID"}
          </Typography>
          <Typography fontSize={12}>{subType}</Typography>
        </Grid>
        <Grid item xs={3.5}>
          <Typography fontSize={14} variant="h6">
            Transaction Number
          </Typography>
          <Typography fontSize={12}>{transactionNumber}</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography fontSize={14} variant="h6">
            {_parseDate(createdAt)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Chip
            label={amount + " ₹"}
            variant="outlined"
            sx={{ minWidth: 100, color: bgColor, borderColor: bgColor }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionCardItem;

import { useEffect, useState } from "react";
import pxToRem from "../../../Themes/Functions/pxToRem";
import AmountInfoCard from "./components/AmountInfoCard";
import AmountToPaidCard from "./components/AmountToPaidCard";
import LastMonthReport from "./components/LastMonthReport";
import NumberOfConsumersCard from "./components/NumberOfConsumersCard";
import AppService from "../../../Configurations/Appservice";
import { useDispatch } from "react-redux";
import { dismissLoader, showLoader } from "../../../LayoutContainers/AppLayout/redux/AppSlice";

const { Box, Grid, Typography, Card } = require("@mui/material");

const CoordinatorDash = (props) => {
  const dispatch = useDispatch();

  const [isLoading, loading] = useState(false);

  const [allocatedAmount, setAllocatedAmount] = useState(0);
  const [numberOfCustomers, setNumberOfConsumers] = useState(0);
  const [claimedAmount, setClaimedAmount] = useState(0);
  const [utilisedAmount, setUtilisedAmount] = useState(0);
  const [amountToPaid, setAmountToPaid] = useState(0);
  const [availablefund, setAvailablefund] = useState(0);
  const [basedOn, setBasedOn] = useState("");

  useEffect(() => {
    dispatch(showLoader());
    AppService.get("treat/api/v1/dashboard/coordinator")
      .then((response) => {
        // alert(JSON.stringify(response));
        setNumberOfConsumers(response?.numberOfConsumers || 0);
        setAllocatedAmount(response?.allocatedAmount || 0);
        setClaimedAmount(response?.claimedAmount || 0);
        setUtilisedAmount(response?.utilisedAmount || 0);
        setAmountToPaid(response?.amountToPaid || 0);
        setAvailablefund(response?.availablefund || 0);
        setBasedOn(response?.basedOn || "");
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  }, []);

  return (
    <Box
      sx={{
        height: `calc(100vh - ${pxToRem(80)})`,
        mt: pxToRem(-8),
      }}
    >
      <Grid container>
        <Grid item xs={3}>
          <AmountInfoCard label="Available Fund" value={availablefund} loading={isLoading} />
        </Grid>
        <Grid item xs={3}>
          <AmountInfoCard label="Allocated Amount" value={allocatedAmount} loading={isLoading} />
        </Grid>
        <Grid item xs={3}>
          <AmountInfoCard label="Claimed Amount" value={claimedAmount} loading={isLoading} />
        </Grid>
        <Grid item xs={3}>
          <AmountInfoCard label="Utilised Amount" value={utilisedAmount} loading={isLoading} />
        </Grid>

        <Grid item xs={6}>
          <NumberOfConsumersCard number={numberOfCustomers} />
          <AmountToPaidCard amount={amountToPaid} />
          {/* <IconCard /> */}
        </Grid>
        <Grid item xs={6}>
          <LastMonthReport />
        </Grid>
      </Grid>
      <Typography ml={2} color="gray">
        Dashboard report based on {basedOn}
      </Typography>
    </Box>
  );
};

export default CoordinatorDash;

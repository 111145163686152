import { Box, Card, CircularProgress, Typography } from "@mui/material";
import pxToRem from "../../../../Themes/Functions/pxToRem";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const WalletBalance = ({ balance, loading }) => {
  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "row",
          height: `calc((100vh - ${pxToRem(144)})*0.3)`,
          margin: pxToRem(16),
          mr: 0,
          p: 3,
          cursor: "pointer",
          boxShadow:
            "-4px -5px 4px 0 rgba(217, 217, 233,0.3),4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
        }}
        bgcolor="yellow"
      >
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Typography variant="h4" color="#C51162">
            Wallet Balance
          </Typography>
          <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
            {!loading && (
              <Typography sx={{ fontSize: 50, fontWeight: 500 }}>{balance} ₹</Typography>
            )}
            {loading && <CircularProgress sx={{ mx: 2 }} />}
          </Box>
        </Box>
        <Box
          sx={{
            p: 2,
            pr: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              background: "rgb(83, 29, 120)",
              background:
                "linear-gradient(41deg, rgba(83,29,120,1) 21%, rgba(253,29,29,1) 100%, rgba(69,252,233,1) 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
              borderRadius: 2,
              boxShadow:
                "-4px -5px 4px 0 rgba(217, 217, 233,0.3),4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
            }}
          >
            <AccountBalanceWalletIcon sx={{ fontSize: 60, color: "#ffffff" }} />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default WalletBalance;

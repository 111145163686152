import WDBox from "../../MaterialComponents/WDBox";

const BasicLayout = ({ children }) => {
  return (
    <WDBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      sx={{ overflowX: "hidden" }}
    >
      {children}
    </WDBox>
  );
};

export default BasicLayout;

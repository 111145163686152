import { configureStore } from "@reduxjs/toolkit";
import appSlice from "../../LayoutContainers/AppLayout/redux/AppSlice";
import consumerDashSlice from "../../ScreenLayouts/Dashboard/ConsumerDash/ConsumerDashSlice";

export default configureStore({
  reducer: {
    app: appSlice,
    consumerDashSlice: consumerDashSlice,
  },
});

import pxToRem from "../../Functions/pxToRem";
import borders from "../basics/borders";
import colors from "../basics/colors";

const { white } = colors;
const { borderRadius } = borders;

const drawer = {
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {
      width: pxToRem(250),
      backgroundColor: white.main,
      // height: `calc(100vh - ${pxToRem(32)})`,
      height: `calc(100vh)`,
      // margin: pxToRem(16),
      // borderRadius: borderRadius.xl,
      border: "none",
      paddingBottom: pxToRem(20),
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};

export default drawer;

import { Avatar, Box, Card, Skeleton, Typography } from "@mui/material";
import pxToRem from "../../../../Themes/Functions/pxToRem";
import TransactionCardItem from "./TransactionCardItem";
import { useEffect, useState } from "react";
import AppService from "../../../../Configurations/Appservice";
import TransactionCardItemLoader from "./TransactionCardItemLoader";
import { useDispatch, useSelector } from "react-redux";
import { setTransactions } from "../ConsumerDashSlice";

const TransactionCard = (props) => {
  // const [transactions, setTransactions] = useState([]);
  const [isLoading, loading] = useState(false);
  const [notItem, setNoItem] = useState(false);

  const dispatch = useDispatch();
  const dashStore = useSelector((state) => state.consumerDashSlice);
  const { transactions } = dashStore;

  useEffect(() => {
    loading(true);
    AppService.get("treat/api/v1/transaction/myTransactions")
      .then((response) => {
        if (response.length < 1) {
          setNoItem(true);
        } else {
          dispatch(setTransactions(response));
        }
      })
      .catch(() => {})
      .finally(() => {
        loading(false);
      });
  }, []);

  return (
    <Card
      sx={{
        height: `calc((100vh - ${pxToRem(96)})*0.4)`,
        overflow: "scroll",
        //   p: 2,
        margin: pxToRem(16),
        mt: 0,
        mr: 0,
        p: 2,
        pb: 0,
        boxShadow:
          "inset -4px -5px 4px 0 rgba(217, 217, 233,0.3),inset 4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
      }}
      // bgcolor="blue"
    >
      <Typography color="#673AB7" variant="h4">
        Transaction History
      </Typography>
      {isLoading && (
        <>
          <TransactionCardItemLoader />
          <TransactionCardItemLoader />
        </>
      )}
      {notItem && <Typography>No transaction found</Typography>}
      {!isLoading && (
        <Box sx={{ overflow: "scroll" }}>
          {transactions.map((item) => (
            <TransactionCardItem item={item} />
          ))}
        </Box>
      )}
    </Card>
  );
};

export default TransactionCard;

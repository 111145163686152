import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Spacer from "../../../../Components/Spacer";
import ChipButton from "../../../../Components/ChipButton";
import AppService from "../../../../Configurations/Appservice";
import { useDispatch } from "react-redux";
import {
  showErroMessage,
  showSuccessMessage,
} from "../../../../LayoutContainers/AppLayout/redux/AppSlice";
import { addTransaction } from "../ConsumerDashSlice";

const OrderDialog = (props) => {
  const { onOrderComplete } = props;
  const [shopDetails, setShopDetails] = useState();
  const [shopNumber, setShopNumber] = useState("");
  const [amount, setAmount] = useState(0);

  const [isShopSearching, shopSearching] = useState(false);
  const [isPaymentLoading, paymentLoading] = useState(false);

  const dispatch = useDispatch();

  const _searchShop = () => {
    shopSearching(true);
    const params = { shopNumber };
    AppService.get("treat/api/v1/shop/details", { params })
      .then((response) => {
        if (!response) {
          dispatch(showErroMessage("Shop not found"));
        } else {
          setShopDetails(response);
        }
      })
      .catch(() => {})
      .finally(() => {
        shopSearching(false);
      });
  };

  const _onPayment = () => {
    paymentLoading(true);
    const payload = {
      shopId: shopDetails.id,
      amount: amount,
    };
    AppService.post("treat/api/v1/order/placeOrder", payload)
      .then((response) => {
        if (response.transaction) {
          dispatch(addTransaction(response.transaction));
        }
        dispatch(showSuccessMessage("Payment completed successfully"));
        setShopNumber("");
        setShopDetails();
        setAmount();
        onOrderComplete(amount);
      })
      .catch(() => {})
      .finally(() => {
        paymentLoading(false);
      });
  };

  const _onClose = () => {
    props.onClose();
    setShopNumber("");
    setShopDetails();
  };

  const SearchShop = () => {
    return (
      <Box sx={{ height: "50vh", width: "40vh", pt: 2 }}>
        <TextField
          fullWidth
          label="Shop Number"
          disabled={isShopSearching}
          value={shopNumber}
          placeholder="Enter Shop Number"
          onChange={(event) => {
            setShopNumber(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Chip label="Search" clickable disabled={isShopSearching} onClick={_searchShop} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };

  const PaymentArea = () => {
    return (
      <Box
        sx={{
          height: "50vh",
          minWidth: "40vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          px: 2,
        }}
      >
        <Avatar sx={{ height: 80, width: 80 }}>{shopDetails.name[0]}</Avatar>
        <Spacer />
        <Typography variant="h5">{shopDetails.name}</Typography>
        <Spacer />
        <TextField
          style={{ fontSize: "100px" }}
          fullWidth
          disabled={isPaymentLoading}
          type="number"
          value={amount}
          placeholder="Amount"
          onChange={(event) => {
            setAmount(event.target.value);
          }}
          inputProps={{ style: { fontSize: 20, textAlign: "center" } }}
        />
        <Spacer height={40} />
        <ChipButton onClick={_onPayment} disabled={amount < 1 || isPaymentLoading}>
          Pay
        </ChipButton>
      </Box>
    );
  };

  return (
    <Dialog {...props} onClose={_onClose}>
      {(isPaymentLoading || isShopSearching) && <LinearProgress />}
      <DialogTitle sx={{ display: "flex", position: "relative", alignItems: "center" }}>
        Order Payment
        <Box sx={{ position: "absolute", right: 20 }}>
          <Chip
            label="cancel"
            clickable
            variant="outlined"
            onClick={_onClose}
            sx={{ color: "red" }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>{shopDetails == null ? SearchShop() : PaymentArea()}</DialogContent>
    </Dialog>
  );
};

export default OrderDialog;

import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import WdForm from "../../../Components/WdForm";
import HierarchySelector from "../../../Components/HierarchySelector";
import { useEffect, useState } from "react";
import Spacer from "../../../Components/Spacer";
import { useDispatch } from "react-redux";
import {
  dismissLoader,
  showLoader,
  showSuccessMessage,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import AppService from "../../../Configurations/Appservice";

const FundTransfer = (props) => {
  const [district, setDistrict] = useState();
  const [area, setArea] = useState();
  const [branch, setBranch] = useState();

  const [amount, setAmount] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateScreenTitle("Fund Transfer"));
    return () => dispatch(updateScreenTitle());
  });

  const _onSubmit = (e) => {
    dispatch(showLoader());
    const payload = {
      transferringTo: branch?.key || area?.key || district?.key,
      amount,
    };

    AppService.post("treat/api/v1/fundAllocation/transfer", payload)
      .then((response) => {
        dispatch(showSuccessMessage("Fund transfered successfully"));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });

    e.preventDefault();
  };

  return (
    <form onSubmit={_onSubmit}>
      <WdForm>
        <Typography color="primary" variant="subtitle2" fontSize={14} mb={2}>
          Transferring to
        </Typography>
        <Grid container spacing={2}>
          <HierarchySelector
            onDistrictChange={setDistrict}
            onAreaChange={setArea}
            onBranchChange={setBranch}
          />
        </Grid>
        <Spacer />
        <Typography color="primary" variant="subtitle2" fontSize={14} mb={2}>
          Amount
        </Typography>
        <Grid container>
          <Grid item xs={3}>
            <TextField
              fullWidth
              // disabled={isLoading}
              type="number"
              value={amount}
              placeholder="Enter Amount"
              onChange={(event) => {
                setAmount(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography fontSize={16}>₹</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </WdForm>
    </form>
  );
};

export default FundTransfer;

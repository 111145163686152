import { Box, Card, Typography } from "@mui/material";
import pxToRem from "../../../../Themes/Functions/pxToRem";
import ReportItem from "./ReportItem";
import Spacer from "../../../../Components/Spacer";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

const NumberOfConsumersCard = ({ number }) => {
  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          height: `calc((100vh - ${pxToRem(166)})*0.33)`,
          margin: pxToRem(16),
          mt: 0,
          mr: 0,
          p: 3,
          cursor: "pointer",
          boxShadow:
            "-4px -5px 4px 0 rgba(217, 217, 233,0.3),4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
        }}
        bgcolor="yellow"
      >
        <Typography variant="h5" color="#00796B">
          Number Of Active Consumers
        </Typography>
        <Box
          // bgcolor="red"
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "space-between",
            mx: 3,
          }}
        >
          <Typography sx={{ fontSize: 50, fontWeight: 500 }}>{number}</Typography>
          <Box
            sx={{
              background: "rgb(83, 29, 120)",
              background:
                "linear-gradient(41deg, rgba(83,29,120,1) 21%, rgba(253,29,29,1) 100%, rgba(69,252,233,1) 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
              // mr: 2,
              borderRadius: 2,
              height: 90,
              width: 90,
              boxShadow:
                "-4px -5px 4px 0 rgba(217, 217, 233,0.3),4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
            }}
          >
            <SentimentSatisfiedAltIcon sx={{ fontSize: 40, color: "#ffffff" }} />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default NumberOfConsumersCard;

import { useState } from "react";
import { Typography, TextField, Box, Snackbar, Alert, Checkbox } from "@mui/material";

import WDBox from "../../../MaterialComponents/WDBox";
import BasicLayout from "../../../LayoutContainers/BasicLayout";
import { Navigate, useNavigate } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import { object, string } from "yup";
import { formCard, formPanel, rightPanel, rootBox } from "./styles";
import pxToRem from "../../../Themes/Functions/pxToRem";
import wisdom from "../../../assets/images/youth_svg.svg";
import svg2 from "../../../assets/images/treat_svg.svg";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import {
  dismissLoader,
  showLoader,
  updateAuthInfo,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import ChipButton from "../../../Components/ChipButton";
import WDLoader from "../../../Components/AppLoader/WDLoader";
import axios from "axios";
import { AUTH_KEY } from "../../../Configurations/AppConfig";

const quranWord = `നിങ്ങളില്‍ ഓരോരുത്തര്‍ക്കും മരണം വരുന്നതിനു മുമ്പായി നിങ്ങള്‍ക്ക് നാം നല്‍കിയതില്‍
നിന്ന് നിങ്ങള്‍ ചെലവഴിക്കുകയും ചെയ്യുക. അന്നേരത്ത് അവന്‍ ഇപ്രകാരം പറഞ്ഞേക്കും: 'എന്‍റെ
രക്ഷിതാവേ, അടുത്ത ഒരു അവധി വരെ നീ എനിക്ക് എന്താണ് നീട്ടിത്തരാത്തത്‌? എങ്കില്‍ ഞാന്‍ ദാനം
നല്‍കുകയും, സജ്ജനങ്ങളുടെ കൂട്ടത്തിലാവുകയും ചെയ്യുന്നതാണ്‌ : Al-Munāfiqūn, Ayah 10`;

const LoginPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const appStore = useSelector((state) => state.app);

  const [showError, setShowError] = useState(false);
  const [isRememberMe, rememberMe] = useState(true);
  const [isLoading, loading] = useState(false);

  const [errorMessage, setErrorMessage] = useState();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
  };

  const initialValues = {
    phoneNumber: "",
    password: "",
  };

  const validationSchema = object({
    phoneNumber: string().required("Phone Number is Mandatory"),
    password: string().required("Password is Mandatory"),
  });

  const _loginHandler2 = (values, formikHelpers) => {
    navigate("coordinator/dash");
  };

  const _loginHandler = (values, formikHelpers) => {
    dispatch(showLoader());

    const payload = {
      ...values,
      phoneNumber: "+91" + values.phoneNumber,
    };
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    axios
      .post(`${baseUrl}/treat/api/v1/auth/login`, payload)
      .then((response) => {
        if (isRememberMe) {
          localStorage.setItem(AUTH_KEY, JSON.stringify(response.data));
        }
        sessionStorage.setItem(AUTH_KEY, JSON.stringify(response.data));
        _navigateToApp(response.data);
      })
      .catch((error) => {
        const erroMessage = error?.response?.data?.message || "something went wrong";
        setErrorMessage(erroMessage);
        setShowError(true);
      })
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  const _navigateToApp = (response) => {
    navigate("coordinator/dash");
  };

  const authInfo = localStorage.getItem(AUTH_KEY) || sessionStorage.getItem(AUTH_KEY);
  if (authInfo && JSON.parse(authInfo)) {
    sessionStorage.setItem(AUTH_KEY, authInfo);
    const authInfoObject = JSON.parse(authInfo);
    dispatch(updateAuthInfo(authInfoObject));

    let url = "coordinator/dash";
    // if (authInfoObject.principalInfo.userType === "ADMIN_ORG") {
    //   url = "ADM-Dash/customer";
    // } else if (authInfoObject.principalInfo.userType === "PARTNER_ORG") {
    //   url = "ptr/dash";
    // } else {
    //   url = "cst/dash";
    // }
    return <Navigate to={url} />;
  }

  return (
    <BasicLayout>
      <WDLoader open={appStore.showLoader} />
      <Snackbar open={showError} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <Box sx={rootBox}>
        <Box sx={formPanel}>
          <Typography variant="h4" color="white.main" sx={{ my: 2, fontFamily: "Montserrat" }}>
            LOGIN PAGE
          </Typography>
          <Box sx={formCard}>
            <Formik
              initialValues={initialValues}
              onSubmit={_loginHandler}
              validationSchema={validationSchema}
            >
              {({ errors, isValid, touched, dirty }) => (
                <Form>
                  <WDBox mb={2}>
                    <Field
                      name="phoneNumber"
                      label="Phone Number"
                      placeholder="Phone Number"
                      fullWidth
                      as={TextField}
                      error={Boolean(errors.phoneNumber) && Boolean(touched.phoneNumber)}
                      helperText={Boolean(touched.phoneNumber) && errors.phoneNumber}
                      inputProps={{ style: { color: "white" } }}
                      InputLabelProps={{
                        sx: {
                          color: "#ffffff",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment>
                            <Typography fontSize={14} sx={{ color: "#ffffff", fontWeight: "500" }}>
                              +91
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </WDBox>
                  <WDBox>
                    <Field
                      name="password"
                      label="Password"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      as={TextField}
                      error={Boolean(errors.password) && Boolean(touched.password)}
                      helperText={Boolean(touched.password) && errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword((state) => !state)}
                            >
                              {showPassword ? (
                                <Visibility fontSize="small" color="white" />
                              ) : (
                                <VisibilityOff fontSize="small" color="white" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        style: { color: "white" },
                      }}
                      InputLabelProps={{
                        sx: {
                          color: "#ffffff",
                        },
                      }}
                    />
                  </WDBox>
                  <Box display="flex" sx={{ alignItems: "center" }}>
                    <Typography fontSize={12} sx={{ color: "#ffffff", fontWeight: "500" }}>
                      Keep me logged in
                    </Typography>
                    <Checkbox
                      size="sm"
                      color="white"
                      checked={isRememberMe}
                      onChange={(event) => {
                        rememberMe(event.target.checked);
                      }}
                    />
                  </Box>

                  <Box>
                    <ChipButton
                      style={{ backgroundColor: "#ffffff", color: "primary.main", width: "50%" }}
                      fullWidth
                      disabled={!dirty || !isValid}
                      type="submit"
                      component="button"
                    >
                      Login
                    </ChipButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: pxToRem(50),
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography color="white.main" sx={{ fontSize: 8, mb: 1 }}>
              - Powered By -
            </Typography>
            <Box component="img" src={wisdom} sx={{ height: pxToRem(60), width: "auto" }} />
          </Box>
        </Box>
        <Box sx={rightPanel}>
          <Box component="img" src={svg2} sx={{ height: pxToRem(80), width: "auto" }} />
          <Typography mx={20} mt={10} variant="h5">
            {quranWord}
          </Typography>
        </Box>
      </Box>
    </BasicLayout>
  );
};

export default LoginPage;

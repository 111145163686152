import { Box, Divider, Paper } from "@mui/material";
import ChipButton from "./ChipButton";

export default function WdForm(props) {
  const { children, noForm } = props;
  return (
    <>
      {/* <Box sx={{ width: "50vh", height: "50vh", backgroundColor: "#F0F0F3" }} p={2}>
        <Box
          sx={{
            width: "25vh",
            height: "25vh",
            backgroundColor: "#F0F0F3",
            borderRadius: 10,
            boxShadow:
              "inset -8px -8px 8px 0 rgba(255,255,255,0.7),inset 8px 8px 8px 0 rgba(174,174,192,0.2)",
          }}
        ></Box>
      </Box> */}
      {/* <Box sx={{ width: "50vh", height: "50vh", backgroundColor: "#ffffff" }} p={2}>
        <Box
          sx={{
            width: "25vh",
            height: "25vh",
            backgroundColor: "#ffffff",
            borderRadius: 2,
            boxShadow:
              "inset -4px -4px 6px 0 rgba(217, 217, 233,0.3),inset 4px 4px 4px 0 rgba(175, 175, 213, 0.5)",
          }}
        ></Box>
      </Box> */}
      <Paper
        sx={{
          boxShadow:
            "inset -4px -5px 4px 0 rgba(217, 217, 233,0.3),inset 4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
        }}
      >
        <Box p={3} sx={{ minHeight: "50vh", display: "flex", flexDirection: "column" }}>
          <Box flex={1}>{children}</Box>
          {!noForm && (
            <>
              <Divider />
              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <ChipButton type="submit" component="button">
                  Save
                </ChipButton>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </>
    // <Paper sx={{ border: 0.5, borderColor: "primary.main" }}>
  );
}

import { Box, Typography } from "@mui/material";

const ReportItem = ({ label }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        pl: 2,
        borderRadius: 5,
        // border: 1,
        // borderColor: "#D8D8D8",
        justifyContent: "space-between",
        boxShadow:
          "inset -4px -5px 4px 0 rgba(217, 217, 233,0.3),inset 4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
      }}
    >
      <Typography variant="h6">{label}</Typography>
      <Box
        sx={{
          display: "flex",
          bgcolor: "#6A1B9A",
          height: "100%",
          px: 8,
          alignItems: "center",
          borderRadius: 5,
          borderTopLeftRadius: 60,
          borderBottomLeftRadius: 60,
        }}
      >
        <Typography fontSize={30} color="#ffffff">
          123
        </Typography>
      </Box>
    </Box>
  );
};

export default ReportItem;

import { Box, FormControl, Grid, InputLabel, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  dismissLoader,
  showLoader,
  showSuccessMessage,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import { useEffect, useState } from "react";
import WdSelect from "../../../Components/WdSelect";
import AppService from "../../../Configurations/Appservice";
import WdForm from "../../../Components/WdForm";
import { AREA, BRANCH, DISTRICT } from "../../../Configurations/AppConfig";
import axios from "axios";

export default function NewHierarchy() {
  const [types, setTypes] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [areas, setAreas] = useState([]);

  const dispatch = useDispatch();
  const [type, setType] = useState("");

  const [state, setState] = useState();

  const [district, setDistrict] = useState("");
  const [area, setArea] = useState("");
  const [name, setName] = useState("");

  const [districtLoading, setDistrictLoading] = useState(false);

  useEffect(() => {
    dispatch(updateScreenTitle("New Hierachy"));
    return () => dispatch(updateScreenTitle(""));
  });

  useEffect(() => {
    dispatch(showLoader());
    axios
      .all([
        AppService.get("treat/api/v1/hierarchy/types"),
        AppService.get("treat/api/v1/hierarchy/state"),
      ])
      .then(
        axios.spread((res1, res2) => {
          const types = res1.map((item) => ({ key: item, value: item }));
          setTypes(types);
          setState(res2);
        })
      )
      .catch(() => {
        alert("something went wrong");
      })
      .finally(() => {
        dispatch(dismissLoader());
      });
  }, []);

  useEffect(() => {
    if (!district) return;
    setArea();
    _loadArea(district);
  }, [district]);

  useEffect(() => {
    if (!type?.trim()) {
      return;
    }
    if ([AREA, BRANCH].includes(type) && districts?.length === 0) {
      dispatch(showLoader());
      const params = {
        hierarchyType: DISTRICT,
        parentId: state.key,
      };
      AppService.get("treat/api/v1/hierarchy/children", { params })
        .then((response) => {
          setDistricts(response);
        })
        .catch(() => {})
        .finally(() => {
          dispatch(dismissLoader());
        });
    } else if (type === BRANCH) {
      _loadArea(district);
    }
  }, [type]);

  const _loadArea = (district) => {
    if (district) {
      dispatch(showLoader());
      const params = {
        hierarchyType: AREA,
        parentId: district,
      };
      AppService.get("treat/api/v1/hierarchy/children", { params })
        .then((response) => {
          setAreas(response);
        })
        .catch(() => {})
        .finally(() => {
          dispatch(dismissLoader());
        });
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    dispatch(showLoader());
    let payload = {};
    if (type === DISTRICT) {
      payload = {
        parentHierarchy: state.key,
        type: DISTRICT,
        name: name,
      };
    } else if (type === AREA) {
      payload = {
        parentHierarchy: district,
        type: AREA,
        name: name,
      };
    } else if (type === BRANCH) {
      payload = {
        parentHierarchy: area,
        type: BRANCH,
        name: name,
      };
    }

    AppService.post("treat/api/v1/hierarchy", payload)
      .then((response) => {
        dispatch(showSuccessMessage(`Hierarchy with type ${type} created successfully`));
        setName("");
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  return (
    <Box>
      <form onSubmit={onSubmit}>
        <WdForm>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="service-label">Hierarchy Type</InputLabel>
                  <WdSelect
                    label="Hierarchy Type"
                    fullWidth
                    value={type}
                    onChange={(event) => {
                      setType(event.target.value);
                    }}
                  >
                    {types}
                  </WdSelect>
                </FormControl>
              </Box>
            </Grid>

            {[AREA, BRANCH].includes(type) && (
              <Grid item xs={3}>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="service-label">District</InputLabel>
                    <WdSelect
                      label="District"
                      fullWidth
                      value={district}
                      onChange={(event) => {
                        setDistrict(event.target.value);
                      }}
                      loading={districtLoading}
                    >
                      {districts}
                    </WdSelect>
                  </FormControl>
                </Box>
              </Grid>
            )}

            {[BRANCH].includes(type) && (
              <Grid item xs={3}>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="service-label">Area</InputLabel>
                    <WdSelect
                      label="Area"
                      fullWidth
                      value={area}
                      onChange={(event) => {
                        setArea(event.target.value);
                      }}
                    >
                      {areas}
                    </WdSelect>
                  </FormControl>
                </Box>
              </Grid>
            )}

            <Grid item xs={3}>
              <Box>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  fullWidth
                  placeholder="Type Name Here"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </WdForm>
      </form>
    </Box>
  );
}

import { FormControl, Grid, InputLabel } from "@mui/material";
import WdSelect from "./WdSelect";
import { memo, useEffect, useState } from "react";
import { AREA, AUTH_KEY, BRANCH, DISTRICT, USER_ROLES } from "../Configurations/AppConfig";
import AppService from "../Configurations/Appservice";
import PropTypes from "prop-types";

const {
  ADMIN,
  STATE_ADMIN,
  STATE_COORDINATOR,
  DISTRICT_COORDINATOR,
  DISTRICT_ADMIN,
  AREA_ADMIN,
  AREA_COORDINATOR,
  BRANCH_ADMIN,
  BRANCH_COORDINATOR,
} = USER_ROLES;

const DISTRICT_WISE_ROLES = [
  ADMIN,
  STATE_ADMIN,
  STATE_COORDINATOR,
  DISTRICT_COORDINATOR,
  DISTRICT_ADMIN,
];
const AREA_WISE_ROLES = [AREA_ADMIN, AREA_COORDINATOR];
const BRANCH_WISE_ROLES = [BRANCH_ADMIN, BRANCH_COORDINATOR];

const HierarchySelector = (props) => {
  let showDistrict = false;
  let showArea = false;
  let showBranch = false;
  const gridWidth = props.gridWidth || 3;

  const { list, onDistrictChange, onAreaChange, onBranchChange } = props;
  const authInfo = JSON.parse(sessionStorage.getItem(AUTH_KEY));
  const userRoles = authInfo?.roles || [];

  const [districtList, setDistrictList] = useState([]);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [district, setDistrict] = useState();

  const [areaList, setAreaList] = useState([]);
  const [areaLoading, setAreaLoading] = useState(false);
  const [area, setArea] = useState();

  const [branchList, setBrnachList] = useState([]);
  const [branchLoading, setBranchLoading] = useState(false);
  const [branch, setBranch] = useState();

  if (DISTRICT_WISE_ROLES.some((role) => userRoles.includes(role))) {
    showDistrict = valuesExistInArray(list, [DISTRICT]);
    showArea = valuesExistInArray(list, [AREA]);
    showBranch = valuesExistInArray(list, [BRANCH]);
  } else if (AREA_WISE_ROLES.some((role) => userRoles.includes(role))) {
    showArea = valuesExistInArray(list, [AREA]);
    showBranch = valuesExistInArray(list, [BRANCH]);
  } else if (BRANCH_WISE_ROLES.some((role) => userRoles.includes(role))) {
    showBranch = valuesExistInArray(list, [BRANCH]);
  }

  function valuesExistInArray(array, valuesToCheck) {
    return valuesToCheck.some((value) => array.includes(value));
  }

  useEffect(() => {
    // alert("list");
    if (!list || list.length === 0) return;
    // alert(list);
    setBrnachList([]);
    setAreaList([]);
    setBrnachList([]);
    setDistrict();
    setArea();
    setBranch();

    if (DISTRICT_WISE_ROLES.some((role) => userRoles.includes(role))) {
      showDistrict = valuesExistInArray(list, [DISTRICT]);
      showArea = valuesExistInArray(list, [DISTRICT, AREA]);
      showBranch = valuesExistInArray(list, [DISTRICT, AREA, BRANCH]);
    } else if (AREA_WISE_ROLES.some((role) => userRoles.includes(role))) {
      showArea = valuesExistInArray(list, [AREA]);
      showBranch = valuesExistInArray(list, [AREA, BRANCH]);
    } else if (BRANCH_WISE_ROLES.some((role) => userRoles.includes(role))) {
      showBranch = valuesExistInArray(list, [BRANCH]);
    }
    if (showDistrict) {
      _loadDistrict();
    } else if (showArea) {
      _loadArea();
    } else if (showBranch) {
      // alert("list");
      _loadBranch();
    }
  }, [list]);

  // useEffect(() => {
  //   if (showDistrict) {
  //     _loadDistrict();
  //   } else if (showArea) {
  //     _loadArea();
  //   } else if (showBranch) {
  //     alert("effect");
  //     _loadBranch();
  //   }
  // }, []);

  const _loadDistrict = () => {
    setDistrictLoading(true);
    const params = { type: DISTRICT };
    AppService.get("treat/api/v1/hierarchy/mapped", { params })
      .then((response) => {
        setDistrictList(response);
      })
      .catch((error) => {})
      .finally(() => {
        setDistrictLoading(false);
      });
  };

  const _loadArea = () => {
    setAreaLoading(true);
    const params = { type: AREA };
    AppService.get("treat/api/v1/hierarchy/mapped", { params })
      .then((response) => {
        setAreaList(response);
      })
      .catch((error) => {})
      .finally(() => {
        setAreaLoading(false);
      });
  };

  const _loadBranch = () => {
    // alert("from load branch");
    setBranchLoading(true);
    const params = { type: BRANCH };
    AppService.get("treat/api/v1/hierarchy/mapped", { params })
      .then((response) => {
        setBrnachList(response);
      })
      .catch((error) => {})
      .finally(() => {
        setBranchLoading(false);
      });
  };

  const _onDistrictChange = (event, key) => {
    const district = { key: event.target.value, value: key?.props?.children };
    onDistrictChange && onDistrictChange(district);
    setDistrict(event.target.value);
    setAreaList([]);
    setBrnachList([]);
    setArea();
    setBranch();

    if (showArea) {
      setAreaLoading(true);
      const params = { parentId: event.target.value, hierarchyType: AREA };
      AppService.get("treat/api/v1/hierarchy/children", { params })
        .then((response) => {
          setAreaList(response);
        })
        .catch(() => {})
        .finally(() => {
          setAreaLoading(false);
        });
    }
  };

  const _onAreaChange = (event, key) => {
    // alert("hello");
    const area = { key: event.target.value, value: key?.props?.children };
    onAreaChange && onAreaChange(area);
    // alert("hello2");
    setArea(event.target.value);
    setBrnachList([]);
    setBranch();
    if (showBranch) {
      setBranchLoading(true);
      const params = { parentId: event.target.value, hierarchyType: BRANCH };
      AppService.get("treat/api/v1/hierarchy/children", { params })
        .then((response) => {
          setBrnachList(response);
        })
        .catch(() => {})
        .finally(() => {
          setBranchLoading(false);
        });
    }
  };

  const _onBranchChange = (event, key) => {
    setBranch(event.target.value);
    const branch = { key: event.target.value, value: key?.props?.children };
    onBranchChange && onBranchChange(branch);
  };

  const DistrictSelector = memo((props) => {
    return (
      <Grid item xs={gridWidth}>
        <FormControl fullWidth>
          <InputLabel id="service-label" required>
            District
          </InputLabel>
          <WdSelect
            label="District"
            fullWidth
            loading={districtLoading}
            value={district}
            onChange={_onDistrictChange}
          >
            {districtList}
          </WdSelect>
        </FormControl>
      </Grid>
    );
  });

  const AreaSelector = memo((props) => {
    return (
      <Grid item xs={gridWidth}>
        <FormControl fullWidth>
          <InputLabel id="service-label" required>
            Area
          </InputLabel>
          <WdSelect
            label="Area"
            fullWidth
            loading={areaLoading}
            // required
            value={area}
            onChange={_onAreaChange}
          >
            {areaList}
          </WdSelect>
        </FormControl>
      </Grid>
    );
  });

  const BranchSelector = memo((props) => {
    return (
      <Grid item xs={gridWidth}>
        <FormControl fullWidth>
          <InputLabel id="service-label" required>
            Branch
          </InputLabel>
          <WdSelect
            label="Branch"
            fullWidth
            loading={branchLoading}
            value={branch}
            onChange={_onBranchChange}
          >
            {branchList}
          </WdSelect>
        </FormControl>
      </Grid>
    );
  });

  return (
    <>
      {showDistrict && <DistrictSelector />} {showArea && <AreaSelector />}
      {showBranch && <BranchSelector />}
    </>
  );
};

HierarchySelector.defaultProps = {
  list: [DISTRICT, AREA, BRANCH],
};

HierarchySelector.prototype = {
  list: PropTypes.array,
};

export default memo(HierarchySelector);

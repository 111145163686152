import { Box, Card, CircularProgress, Typography } from "@mui/material";
import pxToRem from "../../../../Themes/Functions/pxToRem";

const AmountInfoCard = ({ label, value, loading }) => {
  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          height: `calc((100vh - ${pxToRem(144)})*0.3)`,
          margin: pxToRem(16),
          mr: 0,
          p: 3,
          cursor: "pointer",
          boxShadow:
            "-4px -5px 4px 0 rgba(217, 217, 233,0.3),4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
        }}
        bgcolor="yellow"
      >
        <Typography variant="h5" color="#353535">
          {label}
        </Typography>
        <Box display="flex" flex={1} alignItems="center">
          {loading && <CircularProgress sx={{ mx: 2 }} />}
          {!loading && <Typography sx={{ fontSize: 30, fontWeight: 500 }}>{value} ₹</Typography>}
        </Box>
      </Card>
    </Box>
  );
};

export default AmountInfoCard;

import { Field, Form, Formik } from "formik";
import WdForm from "../../../Components/WdForm";
import { Box, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { object, string } from "yup";
import { useEffect, useState } from "react";
import HierarchySelector from "../../../Components/HierarchySelector";
import { useDispatch } from "react-redux";
import {
  dismissLoader,
  showLoader,
  showSuccessMessage,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import AppService from "../../../Configurations/Appservice";

const initialValues = {
  name: "",
  phoneNumber: "",
  password: "",
  address: "",
  email: "",
};

const validationSchema = object({
  name: string().required("Name is Mandatory"),
  phoneNumber: string().required("Phone Number is Mandatory"),
  password: string().required("Password is Mandatory"),
  address: string().required("Address is Mandatory"),
});

export default function NewConsumer(props) {
  const [managedBy, setManagedBy] = useState();
  const [userHierarchy, setUserHierarchy] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateScreenTitle("New Consumer"));
    return () => dispatch(updateScreenTitle(""));
  }, []);

  useEffect(() => {
    dispatch(showLoader());
    AppService.get("treat/api/v1/coordinator/hierarchy")
      .then((response) => {
        setManagedBy(response);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  }, []);

  const _onSubmit = (values, { resetForm }) => {
    dispatch(showLoader());

    const body = {
      ...values,
      phoneNumber: "+91" + values.phoneNumber,
      hierarchyId: userHierarchy.key,
      managedHierarchyId: managedBy.id,
      userType: "CONSUMER",
    };

    AppService.post("treat/api/v1/consumer", body)
      .then((response) => {
        resetForm();
        dispatch(showSuccessMessage("New consumer created successfully"));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={_onSubmit} validationSchema={validationSchema}>
      {({ errors, isValid, touched, dirty }) => (
        <Form>
          <WdForm>
            <Typography color="primary" variant="subtitle2" mb={2}>
              Basic Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box>
                  <TextField
                    required
                    label="Managed By"
                    placeholder="Managed By"
                    fullWidth
                    disabled
                    value={managedBy ? managedBy.name + " " + managedBy.type : ""}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    required
                    name="name"
                    label="Name"
                    placeholder="User Name"
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.name) && Boolean(touched.name)}
                    helperText={Boolean(touched.name) && errors.name}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="phoneNumber"
                    label="Phone Number"
                    placeholder="Phone Number"
                    required
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.phoneNumber) && Boolean(touched.phoneNumber)}
                    helperText={Boolean(touched.phoneNumber) && errors.phoneNumber}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <Typography fontSize={14} sx={{ fontWeight: "500" }}>
                            +91
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="password"
                    label="Password"
                    placeholder="Password"
                    required
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.password) && Boolean(touched.password)}
                    helperText={Boolean(touched.password) && errors.password}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="email"
                    label="Email"
                    placeholder="Email"
                    fullWidth
                    as={TextField}
                    error={Boolean(errors.email) && Boolean(touched.email)}
                    helperText={Boolean(touched.email) && errors.email}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box>
                  <Field
                    name="address"
                    label="Address"
                    required
                    placeholder="Address"
                    fullWidth
                    as={TextField}
                    multiline
                    error={Boolean(errors.address) && Boolean(touched.address)}
                    helperText={Boolean(touched.address) && errors.address}
                  />
                </Box>
              </Grid>

              <HierarchySelector onBranchChange={setUserHierarchy} />
            </Grid>
          </WdForm>
        </Form>
      )}
    </Formik>
  );
}

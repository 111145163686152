import { Box, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import WdForm from "../../../Components/WdForm";
import SearchIcon from "@mui/icons-material/Search";
import Spacer from "../../../Components/Spacer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dismissLoader,
  showLoader,
  showSuccessMessage,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import AppService from "../../../Configurations/Appservice";
import ChipButton from "../../../Components/ChipButton";

const SponsorShip = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [managedBy, setManagedBy] = useState();
  const [sponsor, setSponsor] = useState();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const [amount, setAmount] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateScreenTitle("Sponsorship"));
  }, []);

  const _searchConsumer = () => {
    dispatch(showLoader());
    const params = { phoneNumber: "+91" + phoneNumber };
    AppService.get("treat/api/v1/sponsor/search", { params })
      .then((response) => {
        setSponsor(response);
        setAddress(response?.address);
        setName(response?.name);
        _loadHierarchyData();
      })
      .catch(() => {
        dispatch(dismissLoader());
      })
      .finally(() => {});
  };

  const _loadHierarchyData = () => {
    AppService.get("treat/api/v1/coordinator/hierarchy")
      .then((response) => {
        setManagedBy(response);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  const _submit = () => {
    dispatch(showLoader());
    const payload = {
      amount: amount,
      sponsorId: sponsor.id,
      hierarchyId: managedBy.id,
    };
    AppService.post("treat/api/v1/sponsor/doPayment", payload)
      .then((response) => {
        setSponsor();
        setAddress("");
        setName("");
        setAmount(0);
        dispatch(showSuccessMessage("Payment marked successfully"));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  return (
    <WdForm noForm>
      <Typography color="primary" variant="subtitle2" fontSize={14} mb={2}>
        Sponsor Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            label="Sposor Phone Number"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <Typography fontSize={14} sx={{ fontWeight: "500" }}>
                    +91
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={_searchConsumer}>
                    <SearchIcon fontSize="small" color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            label="Name"
            fullWidth
            disabled
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            label="Address"
            disabled
            fullWidth
            value={address}
            onChange={(event) => {
              setAddress(event.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Spacer />

      <Spacer />
      <Typography color="primary" variant="subtitle2">
        Payment Details
      </Typography>
      <Spacer />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            label="Sponsored To"
            placeholder="Phone Number"
            fullWidth
            value={managedBy ? managedBy?.name + " " + managedBy?.type : ""}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Amount"
            placeholder="Amount"
            fullWidth
            value={amount}
            onChange={(event) => {
              setAmount(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography fontSize={14} sx={{ fontWeight: "500" }}>
                    ₹
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", flexDirection: "row-reverse", mt: 12 }}>
        <ChipButton
          component="button"
          onClick={_submit}
          disabled={!sponsor || amount < 1 || !managedBy}
        >
          Save
        </ChipButton>
      </Box>
    </WdForm>
  );
};

export default SponsorShip;

import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import pxToRem from "../../Themes/Functions/pxToRem";

export default function WDSuccessToast(props) {
  return (
    <Snackbar autoHideDuration={3000} {...props}>
      <Alert sx={{ minWidth: pxToRem(300) }} severity="success">
        {props.message || "succes"}
      </Alert>
    </Snackbar>
  );
}

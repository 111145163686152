import { Icon } from "@mui/material";
import WDAvatar from "../../MaterialComponents/WDAvatar";
import profilePicture from "../../assets/images/team-5.jpg";
import { RiHandCoinFill, RiHandHeartFill } from "react-icons/ri";
import { BsTicketPerforatedFill, BsWalletFill } from "react-icons/bs";
import { FaFileInvoiceDollar, FaUserCog } from "react-icons/fa";
import { GiSettingsKnobs } from "react-icons/gi";

const adminRoute = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "ADM-Dash",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Customer Dashboard",
        key: "customer",
        route: "ADM-Dash/customer",
      },
      {
        name: "Partner Dashboard",
        key: "partner",
        route: "ADM-Dash/partner",
      },
    ],
  },
  {
    type: "collapse",
    name: "Customers",
    key: "ADM-Customer",
    icon: <Icon fontSize="medium">group-minus</Icon>,
    collapse: [
      {
        name: "New Organisation",
        key: "adm-new-customer-organisation",
        route: "ADM-Customer/adm-new-customer-organisation",
      },
      {
        name: "Organisation List",
        key: "adm-customer-organisations",
        route: "ADM-Customer/adm-customer-organisations",
      },
      {
        name: "New User",
        key: "adm-new-customer-user",
        route: "ADM-Customer/adm-new-customer-user",
      },
      {
        name: "User List",
        key: "adm-customer-users",
        route: "ADM-Customer/adm-customer-users",
      },
      {
        name: "Service Mapping List",
        key: "adm-customer-service-mappings",
        route: "ADM-Customer/adm-customer-service-mappings",
      },
      {
        name: "Service Mapping",
        key: "adm-customer-service-mapping",
        route: "ADM-Customer/adm-customer-service-mapping",
      },
      // {
      //   name: "Pending Tickets",
      //   key: "pending-customer-tickets",
      //   route: "ADM-Customer/pending-customer-tickets",
      // },
    ],
  },
  {
    type: "collapse",
    name: "Partners",
    key: "ADM-Partner",
    icon: <Icon fontSize="medium">handshake</Icon>,
    collapse: [
      {
        name: "Organisations",
        key: "adm-partner-organisations",
        route: "ADM-Partner/adm-partner-organisations",
      },
      {
        name: "New Organisation",
        key: "adm-new-partner-organisation",
        route: "ADM-Partner/adm-new-partner-organisation",
      },
      {
        name: "Users",
        key: "adm-partner-users",
        route: "ADM-Partner/adm-partner-users",
      },
      {
        name: "New User",
        key: "adm-new-partner-user",
        route: "ADM-Partner/adm-new-partner-user",
      },
      {
        name: "Service Mapping",
        key: "adm-partner-service-mapping",
        route: "ADM-Partner/adm-partner-service-mapping",
      },
      {
        name: "Service Mappings",
        key: "adm-partner-service-mappings",
        route: "ADM-Partner/adm-partner-service-mappings",
      },
      // {
      //   name: "Pending Payments",
      //   key: "pending-payments",
      //   route: "ADM-Partner/pending-payments",
      // },
      // {
      //   name: "Pending Tickets",
      //   key: "pending-tickets",
      //   route: "ADM-Partner/pending-tickets",
      // },
      {
        name: "Payment",
        key: "partner-payment",
        route: "ADM-Partner/partner-payment",
      },
    ],
  },
  // { type: "title", title: "Service Group", key: "title-docs" },
  {
    type: "collapse",
    name: "Service Group",
    key: "ADM-ServiceGroup",
    // icon: <Icon fontSize="medium">group-work</Icon>,
    icon: <RiHandCoinFill />,
    collapse: [
      {
        name: "Service Groups",
        key: "adm-serviceGroups",
        route: "ADM-ServiceGroup/adm-serviceGroups",
      },
      {
        name: "New Service Group",
        key: "adm-new-serviceGroup",
        route: "ADM-ServiceGroup/adm-new-serviceGroup",
      },
    ],
  },
  {
    type: "collapse",
    name: "Service",
    key: "ADM-Service",
    icon: <RiHandHeartFill />,
    collapse: [
      {
        name: "New Service",
        key: "adm-new-service",
        route: "ADM-Service/adm-new-service",
      },
      {
        name: "List",
        key: "adm-services",
        route: "ADM-Service/adm-services",
      },
    ],
  },
  {
    type: "collapse",
    name: "Ticket",
    key: "ADM-Ticket",
    icon: <BsTicketPerforatedFill />,
    collapse: [
      {
        name: "All Tickets",
        key: "pending-tickets",
        route: "ADM-Ticket/pending-tickets",
      },
      {
        name: "On Behalf Ticket",
        key: "on-behalf-ticket",
        route: "ADM-Ticket/on-behalf-ticket",
      },
    ],
  },
  {
    type: "collapse",
    name: "Wallet",
    key: "ADM-Wallet",
    icon: <BsWalletFill />,
    collapse: [
      {
        name: "Topup Requests",
        key: "topup-request",
        route: "ADM-Wallet/topup-request",
      },
    ],
  },
  // {
  //   type: "collapse",
  //   name: "Invoice",
  //   key: "ADM-Invoice",
  //   icon: <FaFileInvoiceDollar />,
  //   collapse: [
  //     {
  //       name: "Invoice Requests",
  //       key: "invoice-request",
  //       route: "ADM-Invoice/invoice-request",
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: "User Management",
    key: "ADM-USER-MGMNT",
    icon: <FaUserCog />,
    collapse: [
      {
        name: "Change Password",
        key: "change-password",
        route: "ADM-USER-MGMNT/change-password",
      },
    ],
  },
  {
    type: "collapse",
    name: "Agent",
    key: "ADM-AGENT",
    icon: <FaUserCog />,
    collapse: [
      {
        name: "Agent Creation",
        key: "agent-creation",
        route: "ADM-AGENT/agent-creation",
      },
    ],
  },
  {
    type: "collapse",
    name: "Configurations",
    key: "ADM-APPCONFIG",
    icon: <GiSettingsKnobs />,
    collapse: [
      {
        name: "Payment Methods",
        key: "payment_methods",
        route: "ADM-APPCONFIG/payment_methods",
      },
      {
        name: "Reset Password",
        key: "reset_password",
        route: "ADM-APPCONFIG/reset_password",
      },
    ],
  },
];

export default adminRoute;

import { Box } from "@mui/material";

const Spacer = ({ height, width }) => {
  return (
    <Box
      sx={{
        width: width || 20,
        height: height || 20,
      }}
    ></Box>
  );
};

export default Spacer;

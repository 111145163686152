import { Box, Card, Typography } from "@mui/material";
import pxToRem from "../../../../Themes/Functions/pxToRem";
import ReportItem from "./ReportItem";
import Spacer from "../../../../Components/Spacer";

const LastMonthReport = (props) => {
  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          height: `calc((100vh - ${pxToRem(144)})*0.66)`,
          margin: pxToRem(16),
          mt: 0,
          mr: 0,
          p: 3,
          cursor: "pointer",
          boxShadow:
            "-4px -5px 4px 0 rgba(217, 217, 233,0.3),4px 4px 6px 0 rgba(175, 175, 213, 0.6)",
        }}
        bgcolor="yellow"
      >
        <Typography variant="h5" color="#00796B">
          Last Month Report
        </Typography>
        <Spacer />
        <Box display="flex" flex={1} flexDirection="column">
          <ReportItem label="Number of Consumers" />
          <Spacer />
          <ReportItem label="Utilised Amount" />
          <Spacer />
          <ReportItem label="Lapsed Amount" />
        </Box>
      </Card>
    </Box>
  );
};

export default LastMonthReport;

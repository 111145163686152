import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import HierarchySelector from "../../../Components/HierarchySelector";
import ChipButton from "../../../Components/ChipButton";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  dismissLoader,
  showLoader,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import WdTable from "../../../Components/Table/WdTable";
import ScreenBox from "../../../Components/ScreenBox";
import AppService from "../../../Configurations/Appservice";
import { createColumnHelper } from "@tanstack/react-table";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import WdSelect from "../../../Components/WdSelect";
import dayjs from "dayjs";

const colorMap = {
  CLAIMED: "#388E3C",
  UNCLAIMED: "#4A148C",
  LAPSED: "#C2185B",
};

const columnHelper = createColumnHelper();
const columnsData = [
  columnHelper.accessor("consumerName", {
    header: "Consumer Name",
  }),
  columnHelper.accessor("consumerNumber", {
    header: "Consumer Number",
  }),
  columnHelper.accessor("hierarchyName", {
    header: "Managing Hierarchy",
  }),
  columnHelper.accessor("amount", {
    header: "Allocated Amount",
  }),
  columnHelper.accessor("month", {
    header: "Month",
  }),
  columnHelper.accessor("allocatedBy", {
    header: "Allocated By",
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: ({ row }) => (
      <Chip
        label={row?.original?.status}
        // sx={{ minWidth: "50%" }}
        size="small"
        sx={{
          fontSize: 12,
          minWidth: 100,
          color: "#ffffff",
          backgroundColor: colorMap[row?.original?.status] || "black",
        }}
        // style={{ color: "red" }}
        // onClick={() => alert(JSON.stringify(row))}
      />
      // <Chip label="Chip" sx={{ minWidth: "50%" }} onClick={() => alert(JSON.stringify(row))} />
    ),
  }),
];

const FundAllocationDetails = (props) => {
  const dispatch = useDispatch();
  const [district, setDistrict] = useState();
  const [area, setArea] = useState();
  const [branch, setBranch] = useState();
  const [list, setList] = useState([]);
  const [status, setStatus] = useState("");

  useEffect(() => {
    dispatch(updateScreenTitle("Fund Allocation Details"));
    return () => dispatch(updateScreenTitle(""));
  });

  const _search = () => {
    dispatch(showLoader());
    let payload = {
      hierarchyId: branch?.key || area?.key || district?.key,
      date: month,
      status: status,
    };
    AppService.post("treat/api/v1/fundAllocation/search", payload)
      .then((response) => {
        setList(response);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  const [month, setMonth] = useState(dayjs(new Date()));

  return (
    <ScreenBox>
      <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ backgroundColor: "red" }}>
        <Grid container spacing={2} mb={2}>
          <HierarchySelector
            gridWidth={10.5 / 5}
            // key={uuid()}
            //   list={hierarchyList}
            onDistrictChange={setDistrict}
            onAreaChange={setArea}
            onBranchChange={setBranch}
          />
          <Grid item xs={10.5 / 5}>
            <FormControl fullWidth>
              <InputLabel id="service-label">Status</InputLabel>
              <Select
                label="Status"
                fullWidth
                // loading={branchLoading}
                value={status}
                onChange={(event) => {
                  setStatus(event.target.value);
                }}
              >
                <MenuItem value={"UNCLAIMED"}>Un Claimed</MenuItem>
                <MenuItem value={"CLAIMED"}>Claimed</MenuItem>
                <MenuItem value={"LAPSED"}>Lapsed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={10.5 / 5}>
            <DatePicker
              label="Month"
              openTo="month"
              views={["year", "month"]}
              renderInput={(params) => <TextField {...params} />}
              value={month}
              onChange={setMonth}
            />
          </Grid>
          <Grid item xs={1.5}>
            <ChipButton fullWidth onClick={_search}>
              Search
            </ChipButton>
          </Grid>
        </Grid>
        <WdTable columnsData={columnsData} data={list} />
      </LocalizationProvider>
    </ScreenBox>
  );
};

export default FundAllocationDetails;

import { Grid, TextField } from "@mui/material";
import WdTable from "../../Components/Table/WdTable";
import ScreenBox from "../../Components/ScreenBox";
import ChipButton from "../../Components/ChipButton";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateScreenTitle } from "../../LayoutContainers/AppLayout/redux/AppSlice";

export default function AllTickets() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateScreenTitle("All Tickets"));
    return () => dispatch(updateScreenTitle(""));
  });

  return (
    <ScreenBox>
      <Grid container spacing={2} mb={1}>
        <Grid item xs={2.5}>
          <TextField label="District" fullWidth size="small" />
        </Grid>
        <Grid item xs={2.5}>
          <TextField label="Area" fullWidth size="small" />
        </Grid>
        <Grid item xs={2.5}>
          <TextField label="Branch" fullWidth size="small" />
        </Grid>
        <Grid item xs={2.5}>
          <TextField label="Status" fullWidth size="small" />
        </Grid>
        <Grid item xs={2}>
          <ChipButton fullWidth>Search</ChipButton>
        </Grid>
      </Grid>
      <WdTable />
    </ScreenBox>
  );
}

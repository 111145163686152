import moment from "moment";

export const FORMAT_DATE = (date) => {
  return moment.utc(date).local().format("DD-MM-YYYY");
};

export const TO_CAMELSNAKE = (string) => {
  return string.replace(/_([a-z])/g, function (match, group) {
    return group.toUpperCase();
  });
};

export const TOCAMELCASE = (input) => {
  input = input?.toLowerCase() || "";
  let parts = input.split("_");
  const totalParts = parts.length;
  for (let i = 0; i < totalParts; i++) {
    let firstLetter = parts[i].charAt(0).toUpperCase();
    let rest = parts[i].slice(1);
    parts[i] = firstLetter + rest;
  }
  return parts.join(" ");
};

export function StringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

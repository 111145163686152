import { Grid } from "@mui/material";
import HierarchySelector from "../../../Components/HierarchySelector";
import ChipButton from "../../../Components/ChipButton";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  dismissLoader,
  showLoader,
  updateScreenTitle,
} from "../../../LayoutContainers/AppLayout/redux/AppSlice";
import WdTable from "../../../Components/Table/WdTable";
import ScreenBox from "../../../Components/ScreenBox";
import AppService from "../../../Configurations/Appservice";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper();
const columnsData = [
  columnHelper.accessor("coordinatorName", {
    header: "Coordinator Name",
  }),
  columnHelper.accessor("coordinatorType", {
    header: "Coordinator Type",
  }),
  columnHelper.accessor("hierarchyname", {
    header: "Hierarchy",
  }),
  columnHelper.accessor("hierarchyType", {
    header: "Hierarchy Type",
  }),
];

const CoordinatorList = (props) => {
  const dispatch = useDispatch();
  const [district, setDistrict] = useState();
  const [area, setArea] = useState();
  const [branch, setBranch] = useState();
  const [list, setList] = useState([]);

  useEffect(() => {
    dispatch(updateScreenTitle("Coordinator List"));
    return () => dispatch(updateScreenTitle(""));
  });

  const _search = () => {
    dispatch(showLoader());
    const hierarchyId = branch?.key || area?.key || district?.key;
    const params = { hierarchyId };
    AppService.get("treat/api/v1/coordinator/search", { params })
      .then((response) => {
        setList(response);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(dismissLoader());
      });
  };

  const isValidSearchCriteria = () => {
    const hierarchyId = branch?.key || area?.key || district?.key;
    return typeof hierarchyId !== "undefined" && hierarchyId !== null;
  };

  return (
    <ScreenBox>
      <Grid container spacing={2} mb={2}>
        <HierarchySelector
          gridWidth={10 / 3}
          onDistrictChange={setDistrict}
          onAreaChange={setArea}
          onBranchChange={setBranch}
        />
        <Grid item xs={2}>
          <ChipButton fullWidth onClick={_search} disabled={!isValidSearchCriteria()}>
            Search
          </ChipButton>
        </Grid>
      </Grid>
      <WdTable columnsData={columnsData} data={list} />
    </ScreenBox>
  );
};

export default CoordinatorList;

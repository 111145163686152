export const USER_TYPE = {
  ADMIN_ORG: "ADMIN_ORG",
  CUSTOMER_ORG: "CUSTOMER_ORG",
  PARTNER_ORG: "PARTNER_ORG",
};

export const APP_CONSTANTS = {
  ADMIN_DASH: "ADMIN_DASH",
  CUSTOMER_DASH: "CUSTOMER_DASH",
  PARTNER_DASH: "PARTNER_DASH",
};

export const TICKET_ACTIONS = {
  EDIT: "EDIT",
  CLAIM: "CLAIM",
  ASSIGN: "ASSIGN",
  REASSIGN: "REASSIGN",
  UNCLAIM: "UNCLAIM",
  COMPLETE: "COMPLETE",
  CLOSE: "CLOSE",
  INCOMPLETE: "INCOMPLETE",
  REJECT: "REJECT",
};

export const COORDINATOR_TYPE = {
  STATE_COORDINATOR: "STATE_COORDINATOR",
  DISTRICT_COORDINATOR: "DISTRICT_COORDINATOR",
  AREA_COORDINATOR: "AREA_COORDINATOR",
  BRANCH_COORDINATOR: "BRANCH_COORDINATOR",
};
